@import "../../../styles/colors/colors.scss";

$colors: (
  "0": $solar-main-yellow,
  "1": $solar-main-color,
  "2": $solar-main-green,
);

#AnalysisCompleted {
  @each $index, $color in $colors {
    .h2-#{$index} {
      color: $color;
    }
  }

  .mb-4 {
    position: relative;
  }

  .icon-father {
    width: 40px !important;
    height: 40px !important;
    border: 1px solid $card-borderd;
    border-radius: 100%;
    flex-shrink: 0;
  }
  h2,
  p {
    font-size: 12px;
    margin: 0;
  }
  .h2-2 {
    font-size: 16px;
  }
  p {
    color: $solar-main-gray-less-1;
  }
}
