@import "../../styles/colors/colors.scss";

.auto-complete-input {
  width: 100%;
  position: relative;

  input {
    width: 100%;
    padding: 10px 30px;
    background: $solar-main-gray-less-3;
    border: 2px solid $solar-main-gray-less-4;
    border-radius: 10px;
    outline: none;

    &:focus {
      border: 2px solid $solar-main-color;
    }
  }

  .teste {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .autocomplete-dropdown-container {
    position: absolute;
    width: 100%;

    z-index: 99;

    .loading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px 0;
      background-color: $solar-main-gray-less-3;

      .spinner-grow-sm {
        color: $solar-main-color !important;
      }
    }

    .row-dropdown {
      padding: 13px !important;
    }
  }
}
