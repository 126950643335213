.choose-company-center .row {
  height: 100vh !important;
  min-height: 750px;

  .logo-and-description {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    .container-logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;
      height: 200px;
    }
    .button-logout {
      position: absolute;
      right: 0;
      height: 30px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      margin-top: 22px;
      border: none;
      background-color: #fff;
      border-radius: 3px;
      padding: 16px 15px;
      color: #bdbdbd;
      box-shadow: 0px 2.37502px 6.29143px #e0e5eb;
    }
    p {
      font-family: "WorkSans";
      font-style: normal;
      font-weight: 300;
      font-size: 22px;
      line-height: 26px;
      text-align: center;
      letter-spacing: 0.05em;

      color: #545454;
    }

    img {
      width: 155px;
    }
  }

  .col-md-6 {
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  .card {
    width: 100% !important;
    height: 100% !important;
    max-height: 321px !important;
    cursor: pointer;
    border-bottom: 20px solid transparent;
    transition: all 0.3s linear;
    margin-top: -500px;
    background-color: #2D567B;

    @media (max-width: 768px) {
      margin-top: 0px;
    }

    &:hover {
      border-bottom: 20px solid #7EACC4;
    }
    :hover img {
      border: none;
    }

    :hover .border-animation {
      transform: scale(1.1);
      background: linear-gradient(135deg, #7EACC4 100%);

      @media (max-width: 768px) {
        transform: scale(1.1);
      }
    }
  }

  .card-two {

    background-color: #FF601A;
    color: #FFF;

    &:hover {
      border-bottom: 20px solid #FF9E73;
    }
    :hover img {
      border: none;
    }

    :hover .border-animation {
      transform: scale(1.1);
      // background: linear-gradient(135deg, #fccf31 0%, #f55555 100%);

      @media (max-width: 768px) {
        transform: scale(1.1);
      }
    }
  }


  .content-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    height: 100%;

    img {
      width: 200px;
      height: 117px;
      padding: 28px 38px;
      border-radius: 8px;
    }

    .border-animation {
      transition: all 0.3s linear;
      padding: 3px;
      border-radius: 9.94033px;
    }

    p {
      font-family: "WorkSans";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
      /* identical to box height */

      text-align: center;
    }
  }
}
