.cadastro-pendente .engloba-titulo-icones {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.cadastro-pendente .titulo-cadastro-pendente {
    display: flex;
    color: #777;
    font-size: 18px;
    margin-top: 3px;
    font-weight: normal;
    }

.cadastro-pendente .user-plus {
    margin-right: 5px;
}

.cadastro-pendente .alert-success {
    background-color: #d9edf7;
    border: #bce8f1;
    color: #31708f;
}