//
// Forms.scss
//

.form-check {
  position: relative;
}

// checkbox input right
.form-check-right {
  padding-left: 0;
  display: inline-block;
  padding-right: $form-check-padding-start;
  .form-check-input {
    float: right;
    margin-left: 0;
    margin-right: $form-check-padding-start * -1;
  }
  .form-check-label {
    display: block;
  }
}

// checkbox

.form-checkbox-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type="checkbox"] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: "\F012C";
        font-family: "Material Design Icons";
        top: -4px !important;
        left: 1px;
        font-size: 16px;
      }
    }
  }
}

// radio

.form-radio-outline {
  .form-check-input {
    border-width: 2px;
    background-color: $card-bg;

    &:active {
      filter: none;
    }

    &:checked {
      background-color: $card-bg !important;

      &[type="checkbox"] {
        background-image: none;
      }

      &:after {
        position: absolute;
        content: "\F0765";
        font-family: "Material Design Icons";
        top: 6px !important;
        left: 4px;
        font-size: 6px;
      }
    }
  }
}

// checkbox color

@each $color, $value in $theme-colors {
  .form-check-#{$color} {
    .form-check-input {
      &:checked {
        background-color: $value;
        border-color: $value;
      }
    }
  }

  .form-radio-#{$color} {
    .form-check-input {
      &:checked {
        border-color: $value;
        background-color: $value;
        &:after {
          color: $value;
        }
      }
    }
  }
}

.form-check-label {
  cursor: pointer;
  margin-bottom: 0;
}

// Switch sizes

.form-check {
  position: relative;
  text-align: left /*rtl: right*/;
}

.form-switch-md {
  padding-left: 2.5rem;
  min-height: 24px;
  line-height: 24px;
  .form-check-input {
    width: 40px;
    height: 20px;
    left: -0.5rem;
    position: relative;
  }

  .form-check-label {
    vertical-align: middle;
  }
}

.form-switch-lg {
  padding-left: 2.75rem;
  min-height: 28px;
  line-height: 28px;
  .form-check-input {
    width: 48px;
    height: 24px;
    left: -0.75rem;
    position: relative;
  }
}

.input-group-text {
  margin-bottom: 0px;
}

.custom-control-label::before {
  background-color: $input-bg;
}
.form-control {
  background-color: $white !important;
}

// Search ICOn
.custome-search-box {
  .form-control {
    padding-left: 40px;
  }
  .search-icon {
    font-size: 16px;
    position: absolute;
    left: 13px;
    top: 0;
    line-height: 38px;
  }
}
