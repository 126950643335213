.buscar-regras .engloba-limpar-filtros {
    margin-top: 19px;
    padding: 9px 12px !important;
    margin-bottom: -59px;
}
.buscar-regras .engloba-input {
    display: flex;
    align-items: center;
}

.buscar-regras .botao-left,
.buscar-regras .botao-left:hover,
.buscar-regras .botao-left:focus,
.buscar-regras .botao-left:active {
    padding: 5px 10px;
    margin-right: 20px;
    border: 2px solid #2D567B;
    background-color: #fff;
    border-radius: 6px;
}

.buscar-regras .icone-esquerda,
.buscar-regras .icone-esquerda:focus,
.buscar-regras .icone-esquerda:hover,
.buscar-regras .icone-esquerda:active {
    color: #2D567B;
    font-size: 14px;
}
.buscar-regras .input-number {
    margin-right: 20px;
    padding: 5px 10px;
    border: 2px solid #2D567B;
    background-color: #fff;
    border-radius: 6px;
}

.buscar-regras .qnt-pag {
    color: #2D567B;
}
.buscar-regras .botao-right,
.buscar-regras .botao-right:focus,
.buscar-regras .botao-right:hover,
.buscar-regras .botao-right:active {
    margin-left: 20px;
    padding: 5px 10px;
    border: 2px solid #0067b469;
    background-color: #fff;
    border-radius: 6px;
}

.buscar-regras .icone-direita,
.buscar-regras .icone-direita:hover,
.buscar-regras .icone-direita:active,
.buscar-regras .icone-direita:focus {
    color: #0067b469;
    font-size: 14px;
}

.buscar-regras .input-number {
    width: 17%;
}
.buscar-regras .engloba-qnt-total {
    display: flex;
    align-items: center;
}

.buscar-regras .qnt-resultados {
    color: #9b9b9b;
}

.buscar-regras .limpar-filtros {
    color: rgb(0, 103, 180);
    background-color: rgb(255, 255, 255);
    border: none;
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    align-items: center;
}

.buscar-regras .icone-x {
    margin-left: 5px;
}
.buscar-regras .engloba-botoes {
    display: flex;
    align-items: center;
    margin-bottom: 0;
}

.buscar-regras .pesquisar,
.buscar-regras .pesquisar:active,
.buscar-regras .pesquisar:hover,
.buscar-regras .pesquisar:focus {
    color: #fff;
    background-color: rgb(0, 103, 180);
    border: none;
    font-weight: 500;
}
.buscar-regras .linha-pesquisa {
    margin-top: 90px;
    height: 2px;
    color: #d7d7d7;
}
.buscar-regras .engloba-linha-pesquisa {
    margin-bottom: 34px;
    margin-top: 10px;
}
.buscar-regras .engloba-pesquisa {
    background-color: rgb(249 248 248);
    margin-top: -16px;
}
.buscar-regras .iniciodapesquisa {
    margin-bottom: 15px;
}
.buscar-regras .fimdapesquisa {
    margin-bottom: 15px;
}

.buscar-regras label {
    color: rgb(0, 103, 180);
}

.buscar-regras .pesquisa {
    border: 2px solid #d7d7d7;
    border-radius: 10px;
}

.buscar-regras .linha-tabela {
    margin-top: 0px;
    height: 2px;
    color: #d7d7d7;
}