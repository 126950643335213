body[data-sidebar="colored"] .mm-active > a.solar-link {
  background-color: white;
  color: #FF601A !important;
}

body[data-sidebar="colored"] .mm-active > a.solar-link > i {
  color: #FF601A !important;
}

body[data-sidebar="colored"] #sidebar-menu ul li a i.solar-icon {
  color: #7e7e7e;
}

body[data-sidebar="colored"] #sidebar-menu ul li a.solar-link {
  color: #7e7e7e;
}

body[data-sidebar="colored"] #sidebar-menu ul li ul.sub-menu li a.solar-link {
  color: #7e7e7e;
}

body[data-sidebar="colored"] a.mm-active.solar-link {
  color: #FF601A !important;
}

body[data-sidebar="colored"] .mm-active .active .icon-div i.solar-icon {
  color: #FF601A !important;
}

#sidebar-menu .mm-active > .has-arrow .content-has-arrow-down .has-arrow-down {
  -webkit-transform: rotate(180deg) !important;
  transform: rotate(180deg) !important;
}

.mm-active .content-label-arrow {
  display: flex !important;
  justify-content: space-between !important;
  flex-wrap: wrap !important;
}
.content-has-arrow-down {
  flex: auto;
  display: flex;
  justify-content: flex-end;
}
.has-arrow-down {
  transition: transform 0.2s ease 0s, -webkit-transform 0.2s ease 0s;
}

.vertical-collpsed
  .vertical-menu
  .simplebar-content-wrapper
  .has-arrow
  .content-has-arrow-down
  .has-arrow-down {
  display: none;
}

#sidebar-menu ul li a {
  display: flex !important;
  flex-wrap: wrap !important;
}

#sidebar-menu .has-arrow:after {
  content: "" !important;
  display: none !important;
  opacity: 0 !important;
}
