.content-pdf-6 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
  padding-top: 40px;
}

.content-pdf-6 .img-background-rectangle {
  position: absolute;
  right: 0;
  top: 73px;
  z-index: -1;
}

.content-pdf-6 .img-background-rectangle-right {
  position: absolute;
  left: 0;
  bottom: 0px;
  z-index: -1;
}
