.maring-top-3 {
  margin-top: 1.25rem;
}

.py-70 {
  padding-left: 70px;
  padding-right: 70px;
}
@media (max-width: 576px) {
  .py-70 {
    padding-left: var(--bs-gutter-x, 0.625rem);
    padding-right: var(--bs-gutter-x, 0.625rem);
  }
}

.border-radius-30 {
  border-radius: 30px;
}
// .dashboard {
.category {
  .info-icon {
    position: absolute;
    top: 13px;
    right: 13px;
    cursor: pointer;
  }
}
.top-vendors {
  tbody {
    tr {
      td {
        text-align: center;
        &:first-child {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;
        }
        &:last-child {
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
        }
      }
    }
  }
}
// }

.text-muted1 {
  color: #6d7989 !important;
}

.text-muted {
  color: #aebbcd;
  svg {
    path {
      stroke: #aebbcd !important;
    }
    g {
      path {
        fill: #aebbcd !important;
      }
    }
  }
}

.dashboard-timeline {
  .event-list {
    position: relative;
    padding: 0px 0px 30px 30px;
    border-left: 2px solid #f6f6f6;

    &:after {
      content: url("../../../images/svg/shopping_cart.svg");
      position: absolute;
      top: -7px;
      left: -20px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $card-bg;
    }
    &.item-package {
      &:after {
        content: url("../../../images/svg/package.svg");
      }
    }
    &.item-file-text {
      &:after {
        content: url("../../../images/svg/file_text.svg");
      }
    }
    &.item-truck {
      &:after {
        content: url("../../../images/svg/truck.svg");
      }
    }
  }
}

.gurantee-widgets {
  .gurantee-content {
    .widget-icon {
      position: absolute;
      bottom: 14px;
      right: 14px;
    }
  }
}

.float-end {
  float: right;
}

.cursor-pointer {
  cursor: pointer;
}

.chart-tooltip {
  position: relative;
  background-color: $white;
  border: none;
  border-radius: 20px;
  min-width: 150px;
  &:after {
    position: absolute;
    content: "";
    position: absolute;
    margin-left: -15px;
    top: 100%;
    left: 50%;
    width: 0;
    height: 0;
    border-top: solid 15px $white;
    border-left: solid 15px transparent;
    border-right: solid 15px transparent;
  }
}
