.orders .selectData::placeholder {
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    text-align: center;
}

.orders .card-list p {
    margin-bottom: 0;
}

.rulesLabel {
    margin-bottom: 0px;
}

@media (max-width: 756px) {
    .orders .card-list p {
        width: 100% !important;
        text-align: left !important;
        margin-bottom: 10px;
    }

    .orders #dataCad::before {
        content: "Cadastro: ";
    }

    .orders #numPedido::before {
        content: "Pedido: ";
    }

    .orders #nomCli::before {
        content: "Cliente: ";
    }

    .orders #valTot::before {
        content: "Valor: ";
    }

    .orders #filaSit::before {
        content: "Situação: ";
    }

    .orders #vend1::before {
        content: "Vendedor: ";
    }

    .orders #vend2::before {
        content: "Parceiro: ";
    }

    .orders #notf::before {
        content: "Nota Fiscal: ";
    }
}
