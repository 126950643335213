@import "../../styles/colors/colors.scss";

.pdf-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .button-generate-pdf {
    position: fixed;
    right: 20px;
    bottom: 20px;
    padding: 15px 20px;
    background-color: $solar-main-color;
    color: #fff;
    margin: 30px auto;
    margin-top: 10px;
    border-radius: 6px;
    z-index: 999;
  }

  .button-generate-pdf:hover {
    color: $solar-main-gray-less-3 !important;
    background: $solar-main-color-less !important;
  }

  .button-download-pdf {
  }
}
