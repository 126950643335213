$solar-main-color: #FF601A;
$solar-main-color-less: #FF9E73;
$solar-main-color-less-2: #FFE0D3;
$solar-main-yellow: #f4b200;
$solar-main-blue: #009fb4;
$solar-main-green: #25cf84;

$solar-main-gray: #545454;
$solar-main-gray-less-1: #7e7e7e;
$solar-main-gray-less-2: #696969;
$solar-main-gray-less-3: #f5f5f5;
$solar-main-gray-less-4: #d2d2d2;
$solar-main-gray-less-5: #e5e5e5;
$solar-main-gray-less-6: #939393;
$solar-main-gray-less-7: #bdbdbd;
$solar-main-gray-less-8: #a8a8a8;
$solar-main-gray-less-9: #f3eff3;
$solar-main-gray-less-10: #3f3f3f;

$solar-main-gold: #8f7500;

$card-borderd: $solar-main-gray-less-1;
