.container-pdf-10 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
  padding-top: 40px;
}

.img-background-pdf-10 {
  position: absolute;
  right: 0;
  bottom: 0;
  height: 509px;
}

.img-background-pdf-10-right {
  position: absolute;
  left: 0;
  top: 25px;
  height: 300px;
  z-index: -1;
}

.container-pdf-10 .p-pdf-10 {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
  margin: 15px 0;

  padding-right: 40px;
  padding-left: 40px;
}

.container-pdf-10 span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #2a2a2a;
  padding-right: 40px;
  padding-left: 40px;
}

.pdf-10-info {
  display: flex;
  flex-direction: column;
  margin-top: 21px;
}

.pdf-10-info-content {
  display: flex;
  margin: 3px 0;
}

.pdf-10-info-content span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #2a2a2a;
}

.pdf-10-info-content span:first-child {
  margin-right: 10px;
}

.signature-pdf-10 {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 99px;

  font-family: "WorkSans";
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
  color: #2a2a2a;
}

.line-pdf-10 {
  width: 274px;
  border-bottom: 1px solid #000000;
}

.container-pdf-10 .footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-pdf-10 .footer-proposta .footer-p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  padding: 0;
  /* identical to box height, or 190% */
  white-space: nowrap;
  margin: 0;

  color: #d2d2d2;
}
