@import "../../styles/colors/colors.scss";

#analisys-completed {
  background-color: $solar-main-gray-less-5;
  .input-group {
    width: 275px;
  }
  .input-group-text {
    background-color: #fff;
  }
  #card-recommended-size-for-installation {
    strong {
      color: $solar-main-color;
    }
    .h-75 p {
      color: $solar-main-gray-less-6;
    }
    @media only screen and (max-width: 768px) {
      margin-top: 25px;
    }
  }

  .solar-rain-loader {
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  #section-1 {
    height: 430px;
    margin-top: -100px;
    @media only screen and (max-width: 768px) {
      height: auto;
      margin-top: 50px;
      .card {
        border-radius: 20px;
      }
    }
  }
  #section-2 {
    margin: 0 !important;
    margin-top: 175px;
    .active {
      opacity: 1;
    }
    .card {
      height: auto;
    }
    @media only screen and (max-width: 768px) {
      & > :first-child {
        margin: 30px 0 !important;
      }
    }
  }
  #section-2 {
    .card {
      @media only screen and (max-width: 768px) {
        min-height: 425px;
      }
    }
  }
  #section-4 {
    .father-data-with-pictures {
      justify-content: space-between;
      margin: 20px 0 0 0;
      @media only screen and (max-width: 768px) {
        min-height: 425px;
        justify-content: center;
      }
    }
  }
  #section-5 {
    .button-tabs {
      background-color: transparent;
      font-size: 24px;
      font-weight: 400;
      color: $solar-main-gray-less-7;
      @media only screen and (max-width: 768px) {
        font-size: 16px;
        padding: 0;
      }
      &.active {
        font-weight: 700;
        border-bottom: 3px solid $solar-main-color !important;
        color: $solar-main-color;
      }
    }
  }
  #section-6 {
    .border-colored-hover:hover,
    .active {
      box-shadow: 0px 5.6358px 11.2716px rgba(0, 0, 0, 0.11);
      border-color: $solar-main-color;
      i svg {
        filter: invert(40%) sepia(66%) saturate(2899%) hue-rotate(1deg)
          brightness(102%) contrast(106%);
      }
      h3 {
        color: $solar-main-color;
      }
    }

    label {
      background-color: $solar-main-gray-less-5;
      border: 1px solid #000;
      display: flex;
      align-items: center;
    }
    p.separator {
      border-left: 1px solid $solar-main-gray-less-8;
      margin: 5px 25px 0 25px !important;
      padding-left: 25px;
      color: $solar-main-gray-less-8;
      font-size: 16px;
    }
    small {
      font-size: 12px;
      color: $solar-main-gray-less-6;
    }

    #request-budget {
      justify-content: space-between;
      .bordered {
        height: 250px;
        width: 250px;
        @media only screen and (max-width: 768px) {
          height: 50px;
          width: 100%;
          margin-bottom: 20px;
          border: none;
          box-shadow: 0px 5.6358px 11.2716px rgba(0, 0, 0, 0.11);
          & > div {
            width: 100%;
            & .d-flex {
              padding: 0 15px;
              justify-content: space-between;
            }
          }
        }
      }
      .icon-show-mobile {
        display: none;
        @media only screen and (max-width: 768px) {
          display: block;
        }
      }
      .icon-show-desktop {
        display: block;
        @media only screen and (max-width: 768px) {
          display: none;
        }
      }
      @media only screen and (max-width: 768px) {
        justify-content: center;
        & > div {
          h3 {
            font-size: 12px !important;
          }
        }
      }
    }
    @media only screen and (max-width: 768px) {
      .mt-5 {
        margin-top: 0 !important;
      }
    }
  }
  #father-upload-files {
    @media only screen and (max-width: 768px) {
      width: 100%;
      label,
      p {
        font-size: 10px !important;
        text-align: center;
      }
    }
  }
  #tab-buy {
    @media only screen and (max-width: 768px) {
      p {
        margin-bottom: 0 !important;
      }
    }
    .how-financial-your-solar-panels {
      @media only screen and (max-width: 768px) {
        margin-top: 20px;
      }
    }
  }
}
