.content-pdf-4 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
  padding-top: 40px;
}

.rectangles-top {
  position: absolute;
  top: 12.67px;
  right: 0;
}

.rectangles-bottom {
  position: absolute;
  bottom: 150px;
}

.content-pdf-4 p {
  margin: 30px 40px 20px 61px;
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  /* or 136% */

  color: #2a2a2a;
}

.container-logos {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 50px 30px 0 30px;
}

.container-logos .logoOne {
  width: 73.25px;
  height: 45.78px;
}

.absolar {
  width: 126.15px;
  height: 38.66px;
}

.inmetro {
  width: 43.89px;
  height: 43.89px;
}

.procel {
  width: 101.24px;
  height: 43.14px;
}

.logoTwo {
  width: 106.82px;
  height: 33.05px;
}

.footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-proposta p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  /* identical to box height, or 190% */
  white-space: nowrap;
  margin: 0;

  color: #d2d2d2;
}
