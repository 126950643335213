.container-pdf-9 .p-pdf-9 {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
  margin: 15px 0;
}

.table-pdf-9-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: rgb(229, 229, 229);
  font-family: "WorkSans";
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
}

.table-content-pdf-9-two {
  display: grid;
  grid-template-columns: 45% 25% 15% 15%;
}

.table-content-pdf-9-two span {
  margin: 0;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
}

.table-content-pdf-9-two p {
  margin: 0;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
}

.background-pdf-9 {
  background: #fff7f2;
  border-radius: 5px;
  padding: 10px 15px;
}

.container-pdf-10 .footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-pdf-10 .footer-proposta .footer-p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  padding: 0;
  /* identical to box height, or 190% */
  white-space: nowrap;
  margin: 0;

  color: #d2d2d2;
}
