.container-pdf-6-top {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.container-pdf-6-top-header {
  display: flex;
  align-items: center;
}

.container-pdf-6-top-header img {
  margin-right: 18px;
}

.container-pdf-6-top-header span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  letter-spacing: 0.03em;

  color: #FF601A;
}

.container-pdf-6-top-project {
  margin-top: 15px;
}

.container-pdf-6-top-project-info {
  display: flex;
  justify-content: space-between;
  margin: 5px 0;
}

.container-pdf-6-top-project-info span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 11px;
  line-height: 16px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #101317;
}

.container-pdf-6-top-project-info p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 11px;
  line-height: 16px;
  margin: 0;
  text-align: right;
  letter-spacing: 0.03em;

  color: #101317;
}
