@import "../../styles/colors/colors.scss";

#calculator-receives-data {
  .page-content {
    padding: calc(70px + 1.25rem) calc(1.25rem / 2) 60px calc(1.25rem / 2);
  }

  label {
    margin: 0 !important;
  }
  label,
  span {
    font-size: 16px;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
    color: $solar-main-gray-less-10;
  }
  p {
    margin: 0 !important;
  }
  input,
  select {
    width: 100%;
    color: $solar-main-gray-less-9;
  }
  button {
    span {
      color: #fff;
    }
  }

  .input-cifrao {
    position: absolute;
    left: 10px;
    top: 13px;
    font-size: 16px;
    color: #696969 !important;
    font-weight: 400;
  }

  .input-padding {
    padding-left: 46px;
  }
  #mark-choice-consumption {
    background-color: $solar-main-gray-less-9;
    height: 40px;
    border-radius: 10px;
    width: 100%;
    margin-bottom: 15px;
    .mark-choice-consumption-span {
      height: 20px;
      width: 40%;
      background-color: #fff;
      top: 29%;
      transform: translateY(-50%);
      border-radius: 30px;
      transition: all 0.2s linear;
    }
    .mark-choice-consumption-span-right {
      transform: translateX(142%);
    }
    .mark-choice-consumption-span-left {
      transform: translateX(8%);
    }
  }
  .description-input {
    display: flex;
    align-items: center;
    color: #a3a3a3;
    margin-top: 3px !important;
  }
  #section-2 {
    .active-average-consumption {
      color: $solar-main-color;
      font-weight: 700;
    }

    .container-sections {
      display: flex;
      justify-content: space-evenly;

      @media (max-width: 575px) {
        flex-direction: column;
        align-items: center;
      }
    }
    .section-width {
      width: 40%;

      @media (max-width: 575px) {
        width: 100%;
      }
    }

    .container-button-cal {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      height: 120px;
    }

    .center-mobile {
      justify-content: center;
    }

    #monthly-consumption {
      min-height: 400px;
    }
  }
  #section-4 {
    .buttons-mobile {
      @media (max-width: 575px) {
        justify-content: space-between;
        flex-direction: column;
        height: 120px;
      }
    }
    .button-width-mobile {
      @media (max-width: 575px) {
        width: 100%;
      }
    }

    button {
      &.active-default {
        background-color: $solar-main-color;
        span {
          color: #fff;
        }
        svg {
          filter: invert(0%) sepia(1%) saturate(6116%) hue-rotate(180deg)
            brightness(118%) contrast(86%);
        }
      }
      &.active-yellow {
        background-color: $solar-main-yellow;
        span {
          color: #fff;
        }
        svg {
          filter: invert(0%) sepia(1%) saturate(6116%) hue-rotate(180deg)
            brightness(118%) contrast(86%);
        }
      }
      &.inactive {
        background-color: $solar-main-gray-less-3;
        span {
          color: $solar-main-gray-less-4;
        }
        svg {
          filter: invert(33%) sepia(96%) saturate(13%) hue-rotate(325deg)
            brightness(102%) contrast(97%);
        }
      }
    }
  }
  #icon-coming-soon {
    height: 70px;
    width: 70px;
    top: -18px;
    right: -9px;
  }
}

.tooltip-wrapper > .tooltip {
  top: -10px !important;
}
