#col-crop {
  #row-crop {
    min-height: 500px;
  }
  #cropSliders {
    position: absolute;
    bottom: -70px;
  }
  .hover-img-upload {
    width: 150px;
    height: 40px;
    color: #fff;
    font-weight: 700;
  }
  .btn-delete {
    border: none;
    background-color: rgb(255, 245, 248);
    i {
      color: rgb(241, 65, 108);
      font-weight: 700;
    }
    &:hover {
      background-color: rgb(241, 65, 108);
      i {
        color: #fff;
      }
    }
  }
}
