.container-pdf-8 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
  padding-top: 40px;
}

.container-pdf-8 img {
  position: absolute;
  left: 0;
  top: 0;
  transform: rotate(180deg);
  bottom: 0;
  height: 100%;
  width: 430px;
}

.rectangle-pdf-four {
  z-index: -1;
}

.container-pdf-8 .table-div {
  padding-left: 40px;
  padding-right: 40px;
}

.container-pdf-8 table {
  width: 100%;
  border: 1px solid #FF601A;
}

.container-pdf-8 .header-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF601A;
  font-weight: bold;
  color: #fff;
  font-size: 13px;
  padding: 2px;
}

.container-pdf-8 tr:nth-child(even) {
  background-color: #e5e5e56e;
}

.container-pdf-8 table tr th {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 12px;
  /* identical to box height */

  text-align: center;
}

.container-pdf-8 table tr td {
  position: relative;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  text-align: center;
}

.container-pdf-8 table tr .strong {
  font-weight: bold;
}

.container-pdf-8 p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
  margin-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.container-pdf-8 .footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-pdf-8 .footer-proposta .footer-p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  padding: 0;
  /* identical to box height, or 190% */
  white-space: nowrap;
  margin: 0;

  color: #d2d2d2;
}

.table-value-dollar-sign {
  position: absolute;
  left: 5px;
  top: 2px;
}

.table-value {
  position: absolute;
  right: 5px;
  top: 2px;
}
