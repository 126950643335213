.container-pdf-7 .container-bottom {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 35px;
}

.container-pdf-7 .container-bottom h1 {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  text-align: center;

  color: #2a2a2a;
}

.container-pdf-7 .container-bottom .money {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: #ffffff;
  border: 1px solid #e7e7e7;
  box-sizing: border-box;
  border-radius: 5px;
  width: 100%;
  max-width: 217px;
  margin-top: 15px;
}

.container-pdf-7 .container-bottom .money span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  color: #FF601A;
}

.container-pdf-7 .container-bottom .money p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;
  text-align: right;
  color: #FF601A;
  margin: 0;
}

.container-pdf-7 .container-bottom .container-cards {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.container-pdf-7 .container-bottom .container-cards .card-double {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container-pdf-7 .container-bottom .container-cards .card-double span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 8px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
  margin: 15px 0;
}

.container-pdf-7 .container-bottom .footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-pdf-7 .container-bottom .footer-proposta .footer-p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  padding: 0;
  white-space: nowrap;
  margin: 0;
  color: #d2d2d2;
}

.card-bottom {
  display: grid;
  grid-template-columns: 30% 70%;

  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
