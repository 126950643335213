.content-pdf-1 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
}

.background-image-pdf-1 {
  width: 100%;
  height: 100%;
  background-size: 100%;
  background-image: cover;
  z-index: 1;
}

.content-image {
  position: absolute;
  top: 0;

  z-index: 2;
}

.content-image .image-ellipse {
  position: absolute;
  background-size: contain;
  z-index: 3;
}

.content-image .image-logo {
  position: absolute;
  top: 30px;
  left: 30px;
  width: 206px;
  height: 127px;
  background-size: contain;
  z-index: 4;
}

.content-vector {
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
}

.image-vector {
  width: 100%;
  height: 100%;
}
