@import "../../variables";

$colors: (
  "0167af": #0167af,
);

$weights: (
  "f-w-100": 100,
  "f-w-200": 200,
  "f-w-300": 300,
  "f-w-400": 400,
  "f-w-500": 500,
  "f-w-600": 600,
  "f-w-700": 700,
  "f-w-800": 800,
  "f-w-900": 900,
);

@each $name, $color in $colors {
  .color-#{$name} {
    color: $color;
  }
}

@each $name, $weight in $weights {
  .#{$name} {
    font-weight: $weight;
  }
}

.light-btn {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

label:hover {
  cursor: pointer;
}

.icons-absolute-center-right {
  right: 0;
}

.spinner-border {
  height: 25px !important;
  width: 25px !important;
}

.j-c-center {
  justify-content: center;
  align-items: center;
  vertical-align: middle;
}

.bg-fit {
  background-size: cover;
  object-fit: cover;
}

.label-bg-img:hover {
  transition: 0.4s;
  transform: scale(1.1);
}

input[type="checkbox"] {
  padding: 5px;
}
