.container-pdf-9 .header-pdf-9 {
  display: flex;
  align-items: center;
  padding-right: 40px;
  padding-left: 40px;
}

.container-pdf-9 .header-pdf-9 span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */
  margin-left: 18px;
  letter-spacing: 0.03em;

  color: #FF601A;
}

.container-pdf-9 .p-pdf-9 {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
  margin: 15px 0;
  padding-right: 40px;
  padding-left: 40px;
}

.table-pdf-9 {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 40px;
  padding-left: 40px;
}

.table-content-pdf-9 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid #d2d2d2;
  border-top: none;
  padding: 3px;
}

.table-content-pdf-9:first-child {
  border-top: 1px solid #d2d2d2;
}

.table-content-pdf-9 span {
  margin: 0;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
}

.table-content-pdf-9 p {
  margin: 0;
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 16px;
  text-align: justify;
  color: #2a2a2a;
}

.container-border-pdf-9 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  border: 1px solid #FF601A;
  border-radius: 5px;
  margin: 15px 40px;
}

.container-border-pdf-9 span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #2a2a2a;
}

.container-border-pdf-9 .p-border-pdf-9 {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  text-align: right;
  color: #FF601A;
  margin: 0;
}
