.scroll {
  position: absolute;
  width: 100px;
  height: 30px;
  top: -125px;
}

.content-pdf-5 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
  padding-top: 40px;
}

.rectangle-pdf-5 {
  position: absolute;
  right: 0;
  width: 395px;
  height: 900px;
  z-index: -1;
}

.container-top {
  display: flex;
  flex-direction: column;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.header {
  display: flex;
  flex-direction: column;
}

.header-user {
  display: flex;
}

.header h1 {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height */

  letter-spacing: 0.03em;

  color: #FF601A;
}

.header img {
  width: 20px;
  height: 20px;
  margin-right: 15px;
}

.middle-container-top {
  display: flex;
  flex-direction: column;
}

.content-info {
  display: flex;
  margin: 5px 0;
}

.content-info span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.03em;
  color: #2a2a2a;
  margin-right: 15px;
}

.content-info p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.03em;
  color: #101317;
  margin: 0;
}

.footer-container-top {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3f3f3f;

  margin-top: 31px;
}

.container-middle {
  display: flex;
  padding: 40px 20px;
  align-items: center;
  justify-content: space-between;
}

.card-middle {
  display: flex;
  width: 130px;
  align-items: center;
  flex-direction: column;
}

.card-image-middle-div {
  height: 40px;
}

.image-middle {
  width: 24px;
  height: 32px;
}

.image-middle-consumo {
  width: 18.09px;
  height: 32.15px;
}

.image-middle-placa {
  width: 32px;
  height: 32px;
}

.image-middle-economia {
  width: 32px;
  height: 32px;
}

.card-container-info {
  margin-top: 11px;
}

.card-span-middle {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 500;
  font-size: 8.16377px;
  line-height: 12px;
  text-align: center;

  color: #696969;
}

.card-strong-middle {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 11.4293px;
  line-height: 17px;
  text-align: center;
  color: #3f3f3f;
  margin-right: 7px;
}

.card-p-middle {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 8.16377px;
  line-height: 12px;
  text-align: center;
  margin: 0;
  color: #7e7e7e;
  margin-top: 3px;
}

.container-bottom-top {
  display: flex;
  justify-content: center;
  border-bottom: 0.732353px solid #e7e7e7;
  border-top: 0.732353px solid #e7e7e7;
  padding: 31px 0;
  width: 90%;
  margin: 0 auto;
}

.card-red {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 161px;
  height: 118px;
  border: 0.913283px solid #f7c9c9;
  border-radius: 7.26886px;
  margin-right: 83px;
}

.card-green {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px;
  width: 161px;
  height: 118px;
  background-color: #fff;
  border: 0.913283px solid #bfeabf;
  border-radius: 7.26886px;
}

.container-bottom-content {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 5px;
}

.container-bottom-span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 11.4293px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #cf0000;
}

.container-bottom-content p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 500;
  font-size: 8.16377px;
  line-height: 12px;
  text-align: center;
  margin: 0;
  margin-left: 8px;

  color: #696969;
}

.card-red p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 8.16377px;
  line-height: 12px;
  text-align: center;

  color: #7e7e7e;
}

.container-bottom-span-green {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 11.4293px;
  line-height: 17px;
  /* identical to box height */

  text-align: center;

  color: #13b013;
}

.card-green p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 8.16377px;
  line-height: 12px;
  text-align: center;

  color: #7e7e7e;
}

.container-bottom-bottom {
  margin-top: 31px;
  padding-left: 40px;
  padding-right: 40px;
  display: flex;
  flex-direction: column;
}

.container-bottom-bottom span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #3f3f3f;
}

.container-bottom-info {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 28px;
}

.container-bottom-info-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.container-bottom-info-card:nth-child(1) {
  margin-right: 112px;
}

.container-bottom-info-card img {
  width: 43px;
  height: 37px;
}

.container-bottom-info-card span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 11.4293px;
  line-height: 17px;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 5px;
  color: #3f3f3f;
}

.container-bottom-info-card p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 8.16377px;
  line-height: 12px;
  text-align: center;
  margin: 0;
  color: #7e7e7e;
}

.content-pdf-5 .footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.content-pdf-5 .footer-proposta p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  /* identical to box height, or 190% */
  white-space: nowrap;
  margin: 0;

  color: #d2d2d2;
}
