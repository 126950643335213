.createColaboradores .titulo-colaboradores {
  font-size: 26px;
  font-weight: normal;
  margin-bottom: 20px;
}

.createColaboradores .titulo-card {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.createColaboradores .icone-funcionario {
  margin-right: 5px;
}

.createColaboradores .subtitulos-tabela {
  margin-bottom: 4px;
  font-size: 14px;
}

.createColaboradores .titulo-local {
  margin-bottom: 16px;
  margin-top: 16px;
  font-size: 18px;
  font-weight: normal;
}

.createColaboradores .icones-form {
  background-color: #e5e5e5;
  color: #999999;
}

.createColaboradores .botao-salvar {
  margin-top: 7px;
  padding: 7px 14px;
  font-weight: normal;
  color: #fff;
  background-color: #0072bc;
  border: none;
  min-width: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.createColaboradores .botao-salvar:hover,
.createColaboradores .botao-salvar:focus,
.createColaboradores .botao-salvar:active {
  background-color: #0072bc;
}

.erros {
  color: #ff5564;
}

.loading {
  color: #6c6868;
}

input:disabled {
  background-color: #eee !important;
  cursor: not-allowed;
}
