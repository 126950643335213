.PostCreate .titulo-pag {
    font-size: 26px;
    color: #666;
    margin-bottom: 20px;
    font-weight: 500;
}
.PostCreate .engloba-card  {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.PostCreate .card-add-novo {
    width: 57%;
}

.PostCreate .titulo-card-edit {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    color: #666;
}

.PostCreate .form-edit {
    margin-bottom: 10px !important;
}

.PostCreate .ql-editor {
    height: 200px;
}

.PostCreate .titulo-icone {
    display: flex;
}

.PostCreate .ph-upload {
    margin-right: 10px !important;
}

.PostCreate .ph-question {
    margin-right: 5px;
}

.PostCreate .ph-eye {
    margin-right: 5px;
}

.PostCreate .engloba-icones {
display: flex;
width: fit-content;
margin-left: auto;
}

.PostCreate .engloba-coluna {
    width: 40%;
}

.PostCreate .card-publicar {
   min-width: 100%;
}

.PostCreate .label-publicar {
    width: 218px;
}

.PostCreate .engloba-data-hora {
    display: flex;
}

.PostCreate #exampleDate {
    margin-right: 10px;
}

.PostCreate #exampleSelect {
    margin-bottom: 10px;
}

.PostCreate .botao-salvar {
    display: block;
    width: fit-content;
    margin-left: auto;
}

.PostCreate .ph-tag {
    margin-right: 10px;
}
.PostCreate .nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #555;
    cursor: default;
    background-color: #fff;
    border: 1px solid #ddd;
    border-bottom-color: transparent
}

.PostCreate ul {
    list-style: none;
}

.PostCreate .link-add-categoria {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    color: #0072bc;
}

.PostCreate .ph-users-three {
    margin-right: 10px;
}

.PostCreate .Link-editar {
    color: #0072bc !important;
}

.ModalOrder .Formulario-modal {
    margin-bottom: 19px !important;
}

.ModalOrder #fileModal {
    width: 100%;
}

.ModalOrder #AreaModal {
    height: 221px;
}

.ModalOrder .btnAdicionar,
.ModalOrder .btnAdicionar :hover,
.ModalOrder .btnAdicionar:focus,
.ModalOrder .btnAdicionar:active {
    color: white !important;
    background-color: #35aa47 !important;
    padding: 7px 14px !important;
    font-size: 14px !important;
    font-weight: normal !important;
    border:none !important;
    margin-top: 20px;
}