.imageGallery .engloba-titulo-icone {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: normal;
}

.imageGallery .titulo-pag {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: normal;
}

.engloba-redes-artes-lg .loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.imageGallery img {
  width: 100%;
}

.imageGallery p {
  margin-left: 15px;
  font-weight: bold;
  font-size: 27px;
  margin-bottom: 80px;
  margin-top: 20px;
}
