.container-pdf-11 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px;
}

.img-background-pdf-11 {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 450px;
}

.header-pdf-11 {
  display: flex;
  align-items: center;
}

.header-pdf-11 img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.header-pdf-11 span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  margin-left: 10px;
  color: #FF601A;
}

.container-pdf-11 .header-table {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FF601A;
  font-weight: bold;
  color: #fff;
  font-size: 13px;
  padding: 2px;
}

.container-pdf-11 .table-div {
  margin-top: 15px;
  margin-bottom: 30px;
}

.table-pdf-11 {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.table-content-pdf-11 {
  width: 100%;
  display: grid;
  grid-template-columns: 76% 4% 20%;
  border: 1px solid #d2d2d2;
  border-top: none;
  padding: 2px;
}

.table-content-pdf-11 span {
  font-size: 10px;
  font-weight: bold;
}

.table-content-pdf-11:first-child {
  border-top: 1px solid #d2d2d2;
}

.secondy-table {
  width: 100%;
  display: grid;
  grid-template-columns: 70% 30%;
  border: 1px solid #d2d2d2;
  font-size: 10px;
  span {
    min-height: 16px;
    border-bottom: 1px solid #d2d2d2;
  }

  .content-table-one {
    display: flex;
    flex-direction: column;
    padding: 0 3px;

    :last-child {
      border-bottom: none;
    }
  }

  .content-table-two {
    border-left: 1px solid #d2d2d2;

    .spans-table-two {
      display: grid;
      grid-template-columns: 1fr 1fr;
      padding: 0 3px;
    }

    .left {
      text-align: left;
    }
    .right {
      text-align: right;
    }
  }

  .colored-text {
    color: #FF601A;
    font-weight: bold;
  }
}

.container-pdf-10 .footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-pdf-10 .footer-proposta .footer-p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  padding: 0;
  /* identical to box height, or 190% */
  white-space: nowrap;
  margin: 0;

  color: #d2d2d2;
}
