.container-pdf-7 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
  padding-top: 40px;
  padding-right: 40px;
  padding-left: 40px;
}

.container-pdf-7 .rectangle-page-three {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 43%;
  z-index: -1;
}

.container-pdf-7 .rectangle-page-three-right {
  position: absolute;
  left: 0;
  width: 20%;
  top: 63px;
  z-index: -1;
}
