.gerenciar-promocoes .titulo-pagina {
    color: #666;
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 30px;
}

.gerenciar-promocoes h4 {
    color: #666;
    font-weight: normal;
    font-size: 18px;
}

.gerenciar-promocoes label {
    margin-top: 10px;
}

.gerenciar-promocoes p {
    color: #0072bc;
    font-size: 11px;
}

.gerenciar-promocoes .botao-cadastro {
    padding: 7px 14px;
    background-color: #35aa47 !important;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    border: none;
    margin-top: 30px;
}