.auto-gestao .gestao-adias {
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 40px;
    margin-top: 15px;
}

.auto-gestao .titulo-paineis {
    display: flex;
    align-items: center;
    font-size: 18px;
}

.auto-gestao .icone-titulo-paineis {
    margin-right: 5px;
}

.auto-gestao .criar-dash{
    margin-top: 10px;
    padding: 7px 14px;
    background-color: #45b6af;
    color: #fff;
    border: none;
    font-weight: normal;
    margin-bottom: 70px;
    width: fit-content;
    display: block;
    
}

.auto-gestao .criar-dash:hover,
.auto-gestao .criar-dash:focus,
.auto-gestao .criar-dash:active {
    background-color: #45b6af;
    color: #fff;
    border: none;
}
.auto-gestao .engloba-label-input{
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;


}
.auto-gestao .procurar-label {
    margin-right: 10px;
}

.auto-gestao .input-pesquisa {
    width: 50%;
}

