.select-search {
  position: relative;
  min-height: 38px;

  .placeholderlabel {
    color: #aebbcd;
    margin: 0;
  }

  .form-select {
    text-align: left !important;
  }
  .select-options-container {
    position: relative;
    .select-input {
      position: absolute;
      top: 0;
      border-radius: none;
      border-left: 1px solid #ced4da;
      border-top: none;
      border-right: 1px solid #ced4da;
      border-radius: 0px !important;
      border-bottom-left-radius: 0px !important;
      border-bottom-right-radius: 0px !important;
      z-index: 1001 !important;
    }
    .select-options {
      position: absolute;
      width: 100%;
      border: 1px solid #ced4da;
      border-top: none;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      z-index: 1000 !important;
      background-color: #ffffff;
      max-height: 300px;
      overflow-y: scroll;

      .not-found {
        justify-content: center;
      }

      .options {
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        min-height: 31.61px;
        padding: 5px;
      }
      .options:hover {
        background-color: #ced4da;
      }

      .options:first-child {
        margin-top: 38px;
      }
    }
  }
}
