@import "./icons.scss";

// Dark Theme
// @import "./bootstrap-dark.scss";
// @import "./app-dark.scss";

// Light Theme
@import "./bootstrap.scss";
@import "./app.scss";

// // RTL Mode
// @import "./rtl/bootstrap-rtl";
// @import "./rtl/components-rtl";
// @import "./rtl/float-rtl";
// @import "./rtl/general-rtl";
// @import "./rtl/pages-rtl";
// @import "./rtl/plugins-rtl";
// @import "./rtl/spacing-rtl";
// @import "./rtl/structure-rtl";
// @import "./rtl/text-rtl";

.close {
  border: 0;

  font-size: 10px;
  padding: 1.35rem 1.25rem;
  background: transparent
    url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e")
    center/1em auto no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.5;
  width: 1em;
  height: 1em;
  z-index: 2;

  span {
    display: none;
  }
}

body {
  * {
    outline: none;
  }
}

.react-bootstrap-table-page-btns-ul {
  margin-bottom: 0px;
}

.rbt-aux .close {
  top: 7px !important;
  right: 8px !important;
  padding: 0;
}

.rangeslider__fill {
  background-color: #556ee6 !important;
}
.rdw-editor-main {
  border: 1px solid $gray-300;
  height: 239px;
}
.dz-message {
  text-align: center;
  margin: 2em 0;
}
//Crypto enteries
.bs-select select {
  width: auto !important;
}

.apexcharts-canvas {
  text {
    fill: #adb5bd !important;
  }
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background-color: #696969;
  color: #393939;
  border-radius: 50%;
}

.carousel-indicators li {
  box-sizing: content-box;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  width: 10px;
  height: 10px;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 50%;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}

//preloader
.is-loading {
  position: relative;
}
.preloader-component {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba($white, 0.75);
  z-index: 9999;
  width: 100%;
}
.status {
  width: 40px;
  height: 40px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -20px 0 0 -20px;
}

//FlatePicker
.flatpickr-months .flatpickr-month {
  background: $primary !important;
}
.flatpickr-weekdays {
  background: $primary !important;
}
span.flatpickr-weekday {
  background: $primary !important;
}
.flatpickr-current-month .flatpickr-monthDropdown-months {
  background: $primary !important;
}
span.flatpickr-weekday {
  color: $white !important;
}
.flatpickr-day.selected {
  background: $primary !important;
}

.selection-cell-header {
  display: none;
}

// editor page custom-border
.custom-border {
  border-color: #dedede !important;
}

.big-icon {
  position: absolute;
  left: -15px;
  top: -36px;
  font-size: 120px;
  opacity: 0.05;
}

.table-borderless {
  th,
  td,
  thead th,
  tbody + tbody {
    border: 0;
  }
}

/*
common
*/
.text-blue {
  color: #269fec !important;
}
.border-light-1 {
  border-color: #f3f4f5 !important;
}
.card-action-custom {
  position: absolute;
  top: 21px;
  right: 21px;
  cursor: pointer;
}

.order-items {
  tbody > tr {
    &.footer-row {
      &:hover {
        --bs-table-accent-bg: transparent;
      }
    }
  }
}
.table-card-border {
  border: 1px solid #ccd2db;
}

/*
events
*/
.event-modal {
  .modal-header {
    .modal-title {
      font-size: 16px !important;
    }
  }
}

.modal-title-custom {
  .modal-title {
    font-size: 16px !important;
  }
  .close {
    box-sizing: content-box;
    color: #000;
    background: transparent url("../../assets/images/close.svg") center/1em auto
      no-repeat;
    border: 0;
    border-radius: 0.25rem;
    opacity: 0.5;
    span {
      display: none !important;
    }
  }
}

.carousel-inner img {
  width: 100%;
}

.order-cliente {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  min-height: 15px;
}

* {
  font-family: "WorkSans";
}

@font-face {
  font-family: "WorkSans";
  src: url(../fonts/Work_Sans/static/WorkSans-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: "WorkSans";
  src: url(../fonts/Work_Sans/static/WorkSans-SemiBold.ttf);
  font-display: swap;
  font-weight: bold;
}


@font-face {
  font-family: "WorkSans";
  src: url(../fonts/Work_Sans/static/WorkSans-BlackItalic.ttf);
  font-display: swap;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url(../fonts/Work_Sans/static/WorkSans-BoldItalic.ttf);
  font-display: swap;
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url(../fonts/Work_Sans/static/WorkSans-Light.ttf);
  font-display: swap;
  font-weight: 300;
}

@font-face {
  font-family: "WorkSans";
  src: url(../fonts/Work_Sans/static/WorkSans-Medium.ttf);
  font-display: swap;
  font-weight: 500;
}

@font-face {
  font-family: "WorkSans";
  src: url(../fonts/Work_Sans/static/WorkSans-MediumItalic.ttf);
  font-display: swap;
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "WorkSans";
  src: url(../fonts/Work_Sans/static/WorkSans-LightItalic.ttf);
  font-display: swap;
  font-weight: 300;
  font-style: italic;
}

@media (max-width: 756px) {
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 15px;
    height: 15px;
  }

  .carousel-indicators li {
    width: 5px;
    height: 5px;
  }
}

.cursor-pointer {
  &:hover {
    cursor: pointer;
  }
}
