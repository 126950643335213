.redemption-benefits {
  .benefit-image {
    width: 128px;
    height: 128px;
    border-radius: 15px;
  }
}

.ranking-progress {
  height: 15px;
  border-radius: 30px;
  .progress-bar {
    border-radius: 30px;
    background: linear-gradient(
      270deg,
      #404b5a -1.24%,
      rgba(64, 75, 90, 0.57) 101.36%
    );
  }
}

.my-rating {
  .widget {
    border-right: 0.5px solid #d5dce5;
  }
}
