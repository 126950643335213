/* =============
   General
============= */

html {
  position: relative;
  min-height: 100%;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: $gray-700;
}

.h1,
h1 {
  font-size: 2.25rem;
}

.h2,
h2 {
  font-size: 1.8rem;
}

.h3,
h3 {
  font-size: 1.575rem;
}

.h4,
h4 {
  font-size: 1.35rem;
}

.h5,
h5 {
  font-size: 1.125rem;
}

.h6,
h6 {
  font-size: 0.9rem;
}

a {
  text-decoration: none !important;
}

label {
  font-weight: 500;
}

// blockquote

.blockquote {
  padding: 10px 20px;
  border-left: 4px solid $gray-300;
}

.blockquote-reverse {
  border-left: 0;
  border-right: 4px solid $gray-300;
  text-align: right;
}

// container

.container,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  @media (min-width: 1200px) {
    max-width: 1140px;
  }
}

// row

.row {
  > * {
    position: relative;
  }
}

// labels

label {
  margin-bottom: $form-label-margin-bottom;
}

// SVG  Icon Size & colors
.icon[class*="text-"] {
  > svg {
    fill: currentColor !important;
  }
  &.icon-outline {
    > svg {
      fill: none !important;
      stroke: currentColor !important;
      path {
        stroke: currentColor !important;
      }
    }
  }
}

.icon-xxxs {
  width: 12px;
  height: 12px;
  > svg {
    width: 12px;
    height: 12px;
  }
}

.icon-xxs {
  width: 15px;
  height: 15px;
  > svg {
    width: 15px;
    height: 15px;
  }
}

.icon-xs {
  width: 18px;
  height: 18px;
  > svg {
    width: 18px;
    height: 18px;
  }
}

.icon-sm {
  width: 22px;
  height: 22px;
  > svg {
    width: 22px;
    height: 22px;
  }
}

.icon-md {
  width: 30px;
  height: 30px;
  > svg {
    width: 30px;
    height: 30px;
  }
}

.icon-lg {
  width: 36px;
  height: 36px;
  > svg {
    width: 36px;
    height: 36px;
  }
}

.icon-xl {
  width: 42px;
  height: 42px;
  > svg {
    width: 42px;
    height: 42px;
  }
}

.svg-drag {
  svg {
    width: 26px;
    height: 26px;
    fill: $gray-600;
  }
}
