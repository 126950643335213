.consultar-orcamento .div-titulo {
    display: flex;
    align-items: flex-end;
    margin-bottom: 20px;
}

.consultar-orcamento .titulo-orcamento {
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 0;
    margin-right: 5px;
}

.consultar-orcamento .subtitulo-orcamento {
    margin-bottom: 0;
    font-size: 15px;
    font-weight: normal;
}

.consultar-orcamento .engloba-pesquisa-chev{
    display: flex;
    justify-content: space-between;
}

.consultar-orcamento .titulo-input {
    font-size: 14px !important;
    
}

.consultar-orcamento .titulo-input::placeholder {
    color: #555 !important;
}

.consultar-orcamento .titulo-card {
    font-size: 19px;
    font-weight: normal;
    display: flex;

}

.consultar-orcamento .cardprincipal {
    margin-bottom: 100px !important;
}
.consultar-orcamento .card-body {
    padding: 30px;
}

.consultar-orcamento .linha-pesquisa {
margin-bottom: 18px;
}

.consultar-orcamento .engloba-vendedor-codigo {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
}

.consultar-orcamento .engloba-nome-cliente {
    margin-left: 20px;
}
.consultar-orcamento .nome-cliente {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
}

.consultar-orcamento .engloba-data-status {
    margin-top: 20px !important;
}

.consultar-orcamento .engloba-status {
    margin-left: 20px;
}

.consultar-orcamento .engloba-inicio-fim {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
}

.consultar-orcamento .fase-pedido {
    border-top: 1px solid #e5e5e5;
    padding-top: 20px;
}

.consultar-orcamento .engloba-código {
    margin-top:  20px !important ;
    margin-bottom: 20px;

}

.consultar-orcamento .fundo-icone {
    background-color: #e5e5e5 !important;
}

.consultar-orcamento .titulos {
    font-size: 18px;
    font-weight: normal;
}

.consultar-orcamento .engloba-botoes {
    display: flex;
    justify-content: flex-end;
}


.consultar-orcamento .botao-limpar {
    margin-left: 10px;
    background-color: #BBCAD2 !important ;
    color: #2D567B !important;
    display: flex !important;
    align-items: center;
    font-weight: normal !important;
    border: none !important;
}

.consultar-orcamento .borracha {
    margin-right: 5px;
}
.consultar-orcamento .botao-busca{
    margin-left: 10px;
    background-color: #2D567B !important ;
    color: #fff !important;
    display: flex !important;
    align-items: center;
    font-weight: normal !important;
}

.consultar-orcamento .botao-busca:hover{
    background-color: #7EACC4;
}

.consultar-orcamento .lupa {
margin-right: 5px;
}
.consultar-orcamento .titulo-lista {
    font-size: 18px;
    font-weight: normal;
    margin-top: 25px;
}
.consultar-orcamento .titulo-input {
    flex: 1;
}

.consultar-orcamento .form-control.color-card {
    background-color: #e5e5e5 !important ;
}