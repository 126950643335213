.orcamento .card-info-orcamento {
  padding: 10px;
}

@media print {
  .orcamento .container{
    max-width:1300px!important;
  }
  .orcamento .buttons-container .btn
  {
    color: transparent;
  }
  .orcamento .engloba-finalizacao-tudo
  {
    page-break-inside: avoid;
  }
  .orcamento .engloba-finalizacao-assinatura
  {
    padding-top: 15px;
  }
}
.orcamento .logo-adias {
  width: 200px;
  margin-right: 20px;
  height: 80px;
}

.orcamento .engloba-logo-texto {
  display: flex;
  justify-content: space-between;
}

.orcamento .engloba-logo-texto p {
  font-weight: 500;
}

.orcamento .adias {
  margin-bottom: 2px;
  margin-top: 10px;
}

.orcamento .info-card {
  text-align: end;
}
.orcamento .telefone {
  margin-top: 10px;
}

.orcamento .info-card p {
  margin-bottom: 2px;
}

.orcamento .card {
  margin-bottom: 30px;
}

.orcamento .card-orcamento {
  display: flex;
  justify-content: space-between;
  padding: 0px;
  background-color: #ccc;
}

.orcamento .titulo-orcamento {
  font-size: 15px;
  margin-top: 10px;
}

.orcamento .data-orcamento {
  margin-top: 10px;
  margin-right: 10px;
  font-weight: 500;
}

.orcamento .tabela-titulo {
  position: relative;
  background-color: #ccc;
}

.orcamento .tabela-orcamento {
  position: absolute;
  right: 12px;
}

.orcamento .subtitulo-tabela {
  text-align: end;
}
.orcamento .titulo-condicoes {
  margin-bottom: 25px;
  margin-top: 20px;
  font-size: 17px;
}
.orcamento .condicoes p {
  margin-bottom: 5px;
}

.orcamento .titulo-equipamento {
  margin-bottom: 25px;
  margin-top: 30px;
  font-size: 17px;
}

.orcamento .condicoes ul {
  margin-bottom: 5px;
}

.orcamento .disposicao {
  margin-top: 150px;
  margin-bottom: 70px;
  font-size: 15px;
  margin-inline-start: 10px;
}

.orcamento .card-info-final {
  margin-top: 300px;
}

.orcamento .finalizacao p {
  margin-bottom: 2px;
  font-weight: 500;
}
.orcamento .subtitulo-assinatura {
  text-align: center;
  margin-bottom: 3px;
}
.orcamento .engloba-finalizacao-assinatura {
  display: flex;
  justify-content: space-between;
  margin-inline-start: 10px;
  margin-inline-end: 13px;
  page-break-inside: avoid;
}

.orcamento .linha-assinatura {
  margin-bottom: 24px;
  font-weight: bold;
  height: 2px;
  color: black;
}
.orcamento .campo-assinatura {
  /* margin-left: 415px; */
  font-weight: 500;
}

.orcamento .engloba-total {
  display: flex;
}

.orcamento .campo-total {
  margin-left: 415px;
  font-weight: 500;
}

.orcamento .declaro-email {
  margin-bottom: 3px;
}

.buttons-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.btn-color {
  background-color: #004476;
  color: #fff;
}

.cancel {
  background-color: #fc5f65;
  color: #fff;
}

.whats {
  background-color: #25d366;
  color: #fff;
}

.whats {
  background-color: #25d366;
  color: #fff;
}

.email {
  background-color: #ffa81f;
  color: #fff;
}

.imprimir {
  background-color: #399892;
  color: #fff;
}

.dowload {
  background-color: #2474f6;
  color: #fff;
}

.title-alert {
  font-size: 20px;
}

.sub-title-alert {
  font-size: 12px;
  margin-top: 20px;
  margin-bottom: 0;
}

.img-product {
  height: 65px;
}

.container-loading-button {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
