.card-modules {
  position: relative;
  box-shadow: none;
  max-height: 250px;

  input {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    opacity: 0;
    z-index: 10 !important;
  }

  .card-border {
    background: #f7f7f7;
    border: 2px solid #d1d1d1;
    border-radius: 5px;
    overflow: hidden;
    text-overflow: ellipsis;

    span {
      z-index: 30 !important;
    }
    .card-box {
      display: flex;
      .card-box-img-input {
        display: flex;
        width: 100%;
        flex-direction: column;

        .card-box-img-info {
          display: flex;
          align-items: center;
          img {
            object-fit: contain !important;
            width: 74px;
            height: 74px;
            margin-top: 5px;
            margin-right: 5px;
            margin-bottom: 5px;
          }
          .card-box-infos {
            p {
              font-size: 11px;
              color: #aebbcd;
              margin: 0px;
            }
          }
        }

        .span-seguro {
          font-size: 9px;
          text-align: center;
          margin-top: 5px;
        }

        .buttons-container {
          display: flex;
          width: 100%;
          align-items: center;
          justify-content: space-between;

          @media (max-width: 1360px) {
            align-items: center;
            justify-content: space-between;
            flex-direction: column-reverse;
          }

          a {
            width: fit-content;
            height: fit-content;

            font-size: 13px;
            color: #fff;

            z-index: 30;
            padding: 5px 27px;

            @media (max-width: 1360px) {
              margin-top: 5px;
            }
          }

          span {
            color: #FF601A;
          }
        }
      }
    }
  }
}
