.row .col .carousel .carousel-indicators li {
  background-color: #222223;
}

.text-ellipsis-columns {
  white-space: nowrap;       
  overflow: hidden;         
  text-overflow: ellipsis;  
  width: 100%;               
  display: inline-block;   
}

