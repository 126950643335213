.DfaEnable .titulo-autenticacao {
    font-size: 26px;
    font-weight: normal;
    color: #666;
    margin-bottom: 20px;
}
.DfaEnable .alert-autenticacao  {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.DfaEnable .subtitulo-autenticacao {
    font-size: 17px;
    font-weight: normal;
    margin-top: 20px;
    margin-bottom: 20px;
}

.DfaEnable .lista-realizar-autenticacao {
    font-size: 13px;
}

.DfaEnable .link-app {
    color: #0072bc;
}

.DfaEnable .engloba-qrcode {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.DfaEnable .token {
    font-size: 17px;
    font-weight: normal;
    margin-top: 5px;
    margin-bottom: 20px;
}

.DfaEnable .btn-ativar-autenticacao {
    color: #fff;
    background-color: #35aa47;
    padding: 7px 14px;
    margin-bottom: 200px;
}