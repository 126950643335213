.font-bold {
  font-weight: bold;
}

.submit-btn {
  width: fit-content !important;
}

input:disabled {
  background-color: #eee !important;
  cursor: not-allowed;
}

.position-spinner {
  position: absolute;
  right: 7px;
  top: 7px;
  width: 1.5rem !important;
  height: 1.5rem !important;
  z-index: 9999999;
}
