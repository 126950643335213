.fechamento-cartao .filtro {
  margin-top: 50px;
}

.fechamento-cartao h2 {
  margin-top: 50px !important;
  margin-bottom: 35px !important;
  color: #202833;
  font-size: 22px;
  font-weight: bold;
}

.fechamento-cartao .form-control {
  background-color: #f5f5f5 !important;
  border: none;
}

.fechamento-cartao .titulos-tabela {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 22px;
  padding-right: 22px;
  color: #005696;
  font-weight: bold;
  font-size: 16px;
}

.fechamento-cartao a {
  color: #269fec;
}

.fechamento-cartao .tabela-nf td {
  border-top-width: 0 !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  font-size: 14px;
  font-weight: 500;
}

.fechamento-cartao .resgatar {
  margin-left: auto;
}

.fechamento-cartao .tabela-nf {
  margin-bottom: 0 !important;
}

.fechamento-cartao .tabela-comissao .card-body {
  padding: 22px !important;
}

.fechamento-cartao .titulo-comissao {
  font-weight: bold;
  font-size: 22px;
}

.fechamento-cartao .card-fechamento {
  padding: 30px 1.25rem;
}
