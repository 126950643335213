
.image-creator h4 {
    font-size: 26px;
    font-weight: normal;
}

.image-creator .ql-editor{
    height: 300px;
}

.image-creator .img-1 {
    height: 87px;
    margin-top: 20px;
}

.image-creator .img-2 {
    height: 87px;
    margin-bottom: 20px;
}
.image-creator .engloba-test-template {
margin-left: 90px;
}
.image-creator .engloba-botoes {
    display: flex;
    width: 250px;
    border: 1px solid #ddd;
    padding: 8px;
}

.image-creator .botao-search,
.image-creator .botao-search:focus,
.image-creator .botao-search:active,
.image-creator .botao-search:hover {
    margin-right: 10px;
    margin-left: 5px;
    color: #fff;
    background-color: #0072bc;
    border: none;
    padding: 7px 14px;
    font-size: 14px
}

.image-creator .botao-trash, 
.image-creator .botao-trash:active,
.image-creator .botao-trash:hover,
.image-creator .botao-trash:focus {
    color: #fff;
    background-color: #f3565d;
    border: none;
    padding: 7px 14px;
    font-size: 14px;
}

.image-creator .botao-salvar,
.image-creator .botao-salvar:focus,
.image-creator .botao-salvar:hover,
.image-creator .botao-salvar:active {
    color: #fff;
    background-color: #45b6af;
    border-color: #3ea49d;
    padding: 7px 14px;
    font-size: 14px;
    font-weight: normal;
    margin-bottom: 10px;
    margin-top: 25px;
}