.noticias .titulo-noticias {
    font-size: 26px;
    font-weight: 500;
    margin-bottom: 15px;
}

.noticias .Card-noticias {
    margin-right: 20px;
    display: block;
    width: 45%;
}
.noticias .info-card-noticias {
 display: flex;
 align-items: center;
 font-size: 13px;
 color: #0b0b0b;
}

.noticias card {
    width: 100%;
}
.noticias .card-text {
    font-size: 17px;
    color: #0a6aa1;
}
.noticias .ph-user {
    margin-right: 5px;
    margin-left: 5px;
}

.noticias .ph-calendar {
    margin-right: 5px;
}
