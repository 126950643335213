.vrf .titulo-fabricantes {
    font-size: 18px;
    font-weight: normal;
}

.vrf .img-trane {
    width: 25%;
}

.vrf .botao-colapse {
    display: flex;
    justify-content: flex-start;
   width: 100%;
   background-color: #f9f9f9;
   margin-bottom: 10px;
   border: 1px solid #e0e0e0;
   box-shadow: none;
}
.vrf .botao-colapse:hover,
.vrf .botao-colapse:focus,
.vrf .botao-colapse:active {
    background-color: #f9f9f9;
    box-shadow: none;
}

.vrf .img-daikin{
    width: 25%;
}

.vrf .imagem-treinamento {
    width: 656px;
    margin-bottom: 30px;
    margin-top: 20px;
}

.vrf h5 {
    font-size: 14px;
    font-weight: normal;
}
.vrf p {
    margin-bottom: 3px
}

.vrf .formas-treinamento {
    font-size: 17px;
    font-weight: 500;
    margin-bottom: 10px;
}

.vrf .icone-formulario {
    margin-right: 10px;
} 
.vrf .texto-duvidas {
    margin-bottom: 20px;
    color: #0179c4;
    font-weight: bold;
}
.vrf .grupo-form{
    margin-bottom: 15px;

}

.vrf .label-form{
    text-align: right;
    color: #0179c4;
}

.vrf .botao-enviar {
    display: block;
    width: fit-content;
    margin-left: auto;
    padding: 7px 14px;
    color: #fff;
    background-color: #35aa47;
    font-size: 14px;
    font-weight: normal;
}