//
// timeline.scss
//

/************** Horizontal timeline **************/

.hori-timeline {
  .events {
    .event-list {
      text-align: center;
      display: block;

      &::after {
        content: "\e858";
        font-family: "unicons-line";
        display: block;
        position: absolute;
        top: 34px;
        left: 0;
        right: 0;
        margin: 0px auto;
        color: $gray-400;
        font-size: 24px;
      }

      &:before {
        content: "";
        display: block;
        position: absolute;
        top: 14px;
        left: 0;
        right: 0;
        width: 100%;
        height: 2px;
        border-radius: 50%;
        background-color: $gray-300;
      }

      .event-date {
        display: inline-block;
        position: relative;
        border-radius: 4px;
        background-color: rgba($primary, 0.2);
        padding: 2px 12px;
        margin-bottom: 48px;
      }
    }
  }
}

/************** vertical timeline **************/

.verti-timeline {
  padding-left: 100px;
  .event-list {
    border-left: 3px dashed $gray-300;
    position: relative;
    padding: 0px 0px 30px 30px;

    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 6px;
      left: -6px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      border: 2px solid $primary;
      background-color: $card-bg;
    }

    .event-date {
      display: inline-block;
      border-radius: 4px;
      background-color: rgba($primary, 0.2);
      color: $primary;
      padding: 2px 12px;
      position: absolute;
      left: -102px;
    }

    &:last-child {
      padding-bottom: 0;
    }
  }
}

// Timeline Orders
.track-order-list {
  margin: 0;
  @media (min-width: 1200px) {
    display: flex;
  }
  li {
    position: relative;
    padding: 8px 0px;
    clear: both;
    @media (max-width: 1198px) {
      text-align: left;
      padding-left: 40px;
      display: flex;
    }
    @media (min-width: 1200px) {
      text-align: center;
      flex-grow: 1;
    }
    &:last-child {
      .progress-line {
        &::before {
          display: none;
        }
      }
    }
    .progress-line {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        top: 7px;
        left: 50%;
        right: 0;
        height: 7px;
        background-color: #f6f6f6;
      }
      .check-icon {
        position: relative;
        display: inline-block;
        padding: 0px;
        z-index: 9;
        width: 20px;
        height: 20px;
        text-align: center;
        border-radius: 50%;
        color: #74788d;
        background-color: #f6f6f6;
        font-size: 15px;
      }
    }
    .step-title {
      margin-top: 8px;
      margin-bottom: 4px;
      font-weight: 700;
      font-size: 14px;
    }
    &.completed {
      .progress-line {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          width: 110%;
          top: 7px;
          left: 50%;
          right: 0;
          height: 7px;
          background-color: #13b013;
        }

        .check-icon {
          position: relative;
          display: inline-block;
          padding: 0px;
          z-index: 9;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          background-color: #13b013;
          font-size: 15px;
          &:before {
            content: "\F0134";
            color: #ffffff;
          }
        }
      }
    }

    @media (max-width: 1198px) {
      .progress-line {
        position: static;
        &::before {
          content: "";
          position: absolute;
          width: 8px;
          top: 52px;
          left: 0px;
          right: 0;
          height: 100%;
          background-color: #f6f6f6;
        }
      }
      &.completed {
        .progress-line {
          position: static;
          &::before {
            content: "";
            position: absolute;
            width: 8px;
            top: 52px;
            left: 0px;
            right: 0;
            height: 100%;
            background-color: #13b013;
          }
        }
      }
      .check-icon {
        position: absolute !important;
        top: 26px;
        left: -10px;
      }
      .order-icon {
        margin-right: 24px;
      }
    }
  }
}
