.DfaValidate .titulo-validacao {
    font-size: 26px;
    font-weight: normal;
    color: #666;
    margin-bottom: 25px;
}

.DfaValidate .subtitulo-validacao  {
    font-size: 17px;
    font-weight: normal;
    color: #666;
    margin-bottom: 10px;
}

.DfaValidate .texto-validacao {
    font-size: 13px;
}
.DfaValidate .formulario-validacao{
    margin-left: 25%;
} 
.DfaValidate .titulo-form {
    font-size: 17px;
    font-weight: normal;
}

.DfaValidate label {
    font-weight: normal;
    margin-bottom: 5px;
    margin-top: 10px;
}

.DfaValidate .btn-ativar-validacao,
.DfaValidate .btn-ativar-validacao:active,
.DfaValidate .btn-ativar-validacao:hover,
.DfaValidate .btn-ativar-validacao:focus {
    color: #fff;
    background-color: #35aa47;
    border: none;
    font-weight: normal;
    margin-top: 10px;
}