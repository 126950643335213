.create-auto .titulo-promocoes {
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 40px;
}

.create-auto .titulo-dash {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
}

.create-auto .input-create {
    margin-bottom: 20px;
}

.create-auto .titulo-grupos {
    margin-top: 80px;
    font-size: 15px;
    font-weight: normal;
}

.create-auto .input-radio {
    margin-right: 5px;
    margin-left: 55px;
}

.create-auto .cadastrar-novo {
    background-color: #45b6af;
    color: #fff;
    font-weight: normal;
    font-size: 14px;
    padding: 7px 14px;
    border: none;
    margin-top: 30px;
}

.create-auto .cadastrar-novo:hover,
.create-auto .cadastrar-novo:focus,
.create-auto .cadastrar-novo:active {
    background-color: #45b6af;
    color: #fff;
}