.userProfile .botao-enviar-file {
  color: #fff;
  font-weight: 500;
  background-color: #35aa47 !important;
  padding: 7px 14px;
  margin-top: 30px;
  border: none;
}

.userProfile label {
  font-size: 14px;
  color: #000;
  font-weight: normal;
}
.userProfile .btn-default,
.userProfile .btn-default:hover,
.userProfile .btn-default:focus,
.userProfile .btn-default:active {
  display: block;
  margin-left: auto;
  background-color: #0072bc;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
  display: flex;
  align-items: center;
}
.userProfile .icone-botao {
  margin-left: 5px;
}

.userProfile .web-chat-texto {
  font-weight: normal;
  color: #000;
  display: flex;
}

.userProfile .input-checkbox {
  margin-left: 10px;
}

.userProfile .salvar-checkbox,
.userProfile .salvar-checkbox:hover,
.userProfile .salvar-checkbox:focus,
.userProfile .salvar-checkbox:active {
  background-color: #35aa47;
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  border: none;
}

.userProfile .menu-meu-perfil {
  color: #495057;
}

.form-group {
  width: 100%;
}

.i-father {
  height: 40px;
  max-height: 40px;
}

.i-father i {
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
}
