.button-main {
  padding: 10px 15px;
  background-color: #FF601A;
  border-radius: 5px;
  color: #fff !important;
  text-decoration: none !important;

  &:hover {
    background-color: #FF9E73;
    color: #fff;
  }
}
