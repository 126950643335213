.card-principal {
  .select {
    display: flex;
    width: 96%;
    height: 38px;
    border: 1px solid #ced4da;
    overflow: hidden;
    border-radius: 0.25rem;
    .select-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 38px;
      background-color: #e5e5e5;
      border-right: 1px solid #ced4da;
    }
  }
}
