.colaboradores .titulo-colaboradores {
  font-size: 26px;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 15px;
}

.colaboradores .icone-plus {
  margin-right: 3px;
}

.colaboradores .botao-cadastro {
  color: #ffff;
  font-size: 14px;
  background-color: #0072bc;
  border: none;
  margin-bottom: 15px;
  font-weight: normal;
  padding: 7px 14px;
  width: fit-content;
  border-radius: 3px;
  display: block;
}

.colaboradores .botao-cadastro:hover,
.colaboradores .botao-cadastro:focus,
.colaboradores .botao-cadastro:active {
  background-color: #0072bc;
}
.colaboradores .card-lista-colaboradores {
  margin-bottom: 40px;
}
.colaboradores .engloba-h4-icone {
  display: flex;
  margin-bottom: 30px;
}

.colaboradores .titulo-card {
  font-size: 18px;
  font-weight: normal;
}

.colaboradores .icone-user-plus {
  font-size: 18px;
}

.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.rdt_TableRow {
  cursor: pointer !important;
  
}

.rdt_TableRow:hover {
  background-color: #eee!important;
  
}
