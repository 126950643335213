.container-pdf-9 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
  padding-top: 40px;
}

.img-background-pdf-9 {
  position: absolute;
  right: 0;
}
