@import url("https://fonts.googleapis.com/css2?family=Barlow:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "../colors/colors.scss";

* {
  font-family: "WorkSans";
}

.solar-styles {
  .f-w-100 {
    font-weight: 100 !important;
  }

  .opacity-100 {
    opacity: calc(0.1);
  }

  .f-w-200 {
    font-weight: 200 !important;
  }

  .opacity-200 {
    opacity: calc(0.2);
  }

  .f-w-300 {
    font-weight: 300 !important;
  }

  .opacity-300 {
    opacity: calc(0.3);
  }

  .f-w-400 {
    font-weight: 400 !important;
  }

  .opacity-400 {
    opacity: calc(0.4);
  }

  .f-w-500 {
    font-weight: 500 !important;
  }

  .opacity-500 {
    opacity: calc(0.5);
  }

  .f-w-600 {
    font-weight: 600 !important;
  }

  .opacity-600 {
    opacity: calc(0.6);
  }

  .f-w-700 {
    font-weight: 700 !important;
  }

  .opacity-700 {
    opacity: calc(0.7);
  }

  .f-w-800 {
    font-weight: 800 !important;
  }

  .opacity-800 {
    opacity: calc(0.8);
  }

  .f-w-900 {
    font-weight: 900 !important;
  }

  .opacity-900 {
    opacity: calc(0.9);
  }

  .f-w-1000 {
    font-weight: 1000 !important;
  }

  .opacity-1000 {
    opacity: calc(1);
  }

  @for $i from 1 through 100 {
    .font-size-#{$i} {
      font-size: #{$i}px !important;
    }
  }

  .big-title {
    font-size: 28px;
    font-weight: 700;
  }
  .under-big-title {
    font-size: 24px;
    @media only screen and (max-width: 768px) {
      font-size: 20px;
      font-weight: 600 !important;
    }
  }
  .main-title,
  label {
    font-size: 18px;
    font-weight: 500;
  }
  .main-title {
    @media only screen and (max-width: 768px) {
      font-size: 14px;
    }
  }
  .under-title {
    font-size: 16px;
  }
  input,
  ::placeholder,
  select,
  textarea {
    font-size: 16px;
    color: $solar-main-gray-less-2 !important;
    font-weight: 400;
  }
  button {
    font-weight: 700;
  }
  &.card {
    &.card-title {
      font-size: 14px;
    }
    & p {
      font-size: 14px;
    }
  }
}
