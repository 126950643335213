@import "../colors/colors.scss";

$directions: "right", "bottom", "left", "top";

.solar-styles {
  @each $direction in $directions {
    .border-#{$direction} {
      border-#{$direction}: 1px solid $solar-main-gray-less-3;
    }
    .icon-absolute-#{$direction} {
      top: 50%;
      #{$direction}: 20px;
      transform: translateY(-50%);
    }
  }

  @for $i from 1 through 1000 {
    .scroll-y-#{$i} {
      max-height: $i;
      overflow-y: auto !important;
    }
    .w-#{$i} {
      width: #{$i} + "%" !important;
    }
  }

  .button-disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  button:disabled {
    cursor: not-allowed !important;
    opacity: 0.3;
  }

  .index-display {
    width: 30px;
    height: 30px;
    background-color: $solar-main-color;
    border-radius: 100%;
    color: #fff !important;
    font-weight: 600;
    &.index-display-no-bg {
      background-color: transparent !important;
    }
  }

  .input-group-text {
    border: 1px solid $solar-main-gray-less-4;
  }

  .j-c-center {
    justify-content: center;
    vertical-align: middle;
    align-items: center;
  }
  .radius-15 {
    border-radius: 15px;
  }
  &.solar-shadow,
  .solar-shadow {
    box-shadow: 0px 5.6358px 11.2716px rgba(0, 0, 0, 0.11);
  }
  &.card {
    padding: 15px;
    border: none;
    border-radius: 15px;
    height: 100%;
  }
  .bordered {
    border: 2px solid $solar-main-gray-less-4;
  }
  button {
    /* min-width: 91.38px; */
    padding-right: 50px;
    padding-left: 50px;
    height: 50px;
    background: $solar-main-color;
    box-shadow: 0px 2.46967px 4.93933px -11.5251px rgba(0, 0, 0, 0.29);
    border-radius: 4.11611px;
    font-size: 18px;
    &.solar-bg-gradient-on-holver {
      border: 1px solid $solar-main-color !important;
      color: $solar-main-color;
      background-color: #fff;
      &:hover {
        color: #fff;
        background: $solar-main-color-less;
        background: -moz-linear-gradient(
          right,
          $solar-main-color-less 0%,
          $solar-main-color 100%
        );
        background: -webkit-linear-gradient(
          right,
          $solar-main-color-less 0%,
          $solar-main-color 100%
        );
        background: linear-gradient(
          to left,
          $solar-main-color-less 0%,
          $solar-main-color 100%
        );
      }
    }
    &.solar-bg-gradient {
      background: $solar-main-color-less;
      background: -moz-linear-gradient(
        left,
        $solar-main-color-less 0%,
        $solar-main-color 100%
      );
      background: -webkit-linear-gradient(
        left,
        $solar-main-color-less 0%,
        $solar-main-color 100%
      );
      color: $solar-main-color-less-2;
      background: linear-gradient(
        to right,
        $solar-main-color-less 0%,
        $solar-main-color 100%
      );
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$solar-main-color-less', endColorstr='$solar-main-color',GradientType=0 );
      &:hover {
        background: $solar-main-color-less;
        background: -moz-linear-gradient(
          right,
          $solar-main-color-less 0%,
          $solar-main-color 100%
        );
        background: -webkit-linear-gradient(
          right,
          $solar-main-color-less 0%,
          $solar-main-color 100%
        );
        background: linear-gradient(
          to left,
          $solar-main-color-less 0%,
          $solar-main-color 100%
        );
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$solar-main-color-less', endColorstr='$solar-main-color',GradientType=0 );
      }
    }
  }
  input,
  select,
  textarea {
    background: #ffffff;
    // box-shadow: 0px 5.6358px 11.2716px rgba(0, 0, 0, 0.11);
    border-radius: 10.5345px;
    font-weight: 600;
    border: 1px solid $solar-main-gray-less-4;
    color: $solar-main-gray-less-1;
    padding-left: 20px;
    outline: none;
    &:hover {
      cursor: pointer;
    }
  }
  .main-solar-bg-light-bordered {
    border: 3px solid $solar-main-color !important;
    background-color: $solar-main-color-less-2;
    color: $solar-main-color;
    font-weight: 600;
    border-radius: 10px;
  }
  input,
  select {
    height: 50px;
  }
  .active {
    font-weight: 700;
  }
  .warning-color {
    color: $solar-main-gold;
  }
  .cursor-pointer {
    cursor: pointer;
  }
  input[type="radio"],
  input[type="checkbox"] {
    accent-color: $solar-main-color;
  }

  .map-father {
    top: 0;
    height: 550px;
    @media only screen and (max-width: 768px) {
      height: 400px;
      position: relative !important;
    }
  }
  .places-auto-complete-mobile {
    display: none;
    @media only screen and (max-width: 768px) {
      display: block;
    }
  }
  .places-auto-complete-desktop {
    display: block;
    @media only screen and (max-width: 768px) {
      display: none;
    }
  }
  @media only screen and (max-width: 768px) {
    p {
      padding-top: 20px !important;
      padding-bottom: 20px !important;
    }
  }
}
