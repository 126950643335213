.dashboard .carousel-control-prev-icon,
.dashboard .carousel-control-next-icon {
  width: 25px !important;
  background-color: transparent !important;
  -webkit-filter: drop-shadow(4px 0px 1px black);
  -moz-filter: drop-shadow(4px 0px 1px black);
  filter: drop-shadow(0px 2px 1px black);
}

.modal-content {
  border: none !important;
}

.dashboard .carousel-control-prev {
  background-image: linear-gradient(
    to left,
    rgba(255, 0, 0, 0),
    rgb(114 114 114)
  );
}
.dashboard .carousel-control-next {
  background-image: linear-gradient(
    to right,
    rgba(255, 0, 0, 0),
    rgb(114 114 114)
  );
}
.dashboard .carousel-control-prev-icon {
  margin-right: 103px;
}

.dashboard .carousel-control-next-icon {
  margin-left: 103px;
}
.dashboard .miniwidget {
  padding: 30px !important;
}

.dashboard .miniwidget h6 {
  font-weight: 500;
}

.dashboard .texto-min {
  font-weight: 500;
}

.dashboard .card-min {
  margin-bottom: 30px !important;
  row-gap: 20px;
}
.dashboard .beneficios {
  color: #005696;
  font-size: 15px;
  font-weight: bold;
}
.dashboard .texto-beneficio {
  font-size: 12px;
  font-weight: 500;
}

.titulo-beneficios {
  font-size: 19px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}
.titulo-timeline {
  font-size: 19px !important;
  font-weight: 500 !important;
  margin-bottom: 15px !important;
}

.titulo-ganhou {
  font-size: 19px !important;
  font-weight: 500 !important;
  margin-bottom: 15px !important;
}
.titulo-garantia {
  font-size: 19px !important;
  font-weight: 500 !important;
  margin-bottom: 10px !important;
}
.dashboard .campo-benefits {
  width: 180px;
  margin: 0 auto;
}

.dashboard .icone {
  margin-right: 30px !important;
}
.dashboard .active h5 {
  color: #2D567B;
}
.dashboard tr:hover td {
  font-weight: 500 !important;
}

.dashboard tr td {
  font-weight: normal !important;
}
.dashboard .titulo-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 10px;
  font-size: 18px;
  font-weight: 500px;
}
.dashboard .back-nav {
  border-radius: 25px;
}
.dashboard .navegacao .active {
  border-radius: 50px !important;
  font-weight: 500;
  color: #ffff !important;
}

.dashboard .ranking-pic {
  width: 50px;
  height: 50px;
}
.dashboard .pic-vendedores {
  width: 28px;
  height: 28px;
  border-radius: 50px;
}

.dashboard .nav-link {
  cursor: pointer;

  transition: none;
  font-weight: normal;
}
.dashboard .tabela-vendedores td {
  text-align: center; /* alinhamento horizontal */
  vertical-align: middle; /* alinhamento vertical */
  font-size: 16px;
  font-weight: 500;
}

.dashboard .tabela-vendedores th {
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  font-size: 16px;
}
.dashboard .info {
  display: block !important;
  width: fit-content !important;
  margin: 0 auto !important;
  padding: 18px 40px !important;
  font-size: 12px;
}

.dashboard .botao-ver {
  background-color: transparent !important;

  padding: 7px 33px !important ;
}

.dashboard .card-title {
  font-size: 18px;
  font-weight: 500;
}
.dashboard .performance-titulo {
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 30px;
  font-weight: bold;
}
/* .dashboard .icone-status svg path {
  stroke: #005696;
} */

.dashboard .icone-status-car svg path {
  fill: #005696 !important;
}

.dashboard .info-perfomance {
  margin-top: 30px !important;
  display: block !important;
  width: fit-content !important;
  margin-left: auto !important;
  padding: 18px 40px;
  font-size: 12px;
}

.dashboard .icone-garantia svg path {
  stroke: #f0f9ff !important;
}

.dashboard .grafico {
  padding: 30px !important;
}
