.imageGallery .engloba-titulo-icone {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: normal;
}

.imageGallery .titulo-pag {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: normal;
}

.engloba-img-list img {
  width: 100%;
  height: 180px;
  max-width: 180px;
}

.engloba-img-list {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.engloba-redes-artes-lg .loading {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .engloba-img-list {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.imageGallery p {
  color: #004476;
  font-weight: normal;
  font-size: 13px;
  margin-bottom: 80px;
  margin-top: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 10px;
}

.engloba-img-list:hover p {
  text-decoration: underline;
}
