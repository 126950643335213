.border {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

button.btn-submit {
  background-color: #4b8df8 !important;
  color: #fff !important;
}

button.btn-cancel {
  background-color: #eee !important;
}

tr:nth-child(odd) {
  background-color: #ffffff;
}
tr:nth-child(even) {
  background-color: #ffffff;
}

.popover > .arrow {
  position: absolute;
  z-index: 1;
  width: 15px !important;
  height: 15px !important;
  background-color: white !important;
  left: 50% !important;
  margin-left: -9px !important;
  transform: rotate(44deg);
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;

  bottom: -7px;
}

.popover {
  z-index: 2;
  top: -11px !important;
  box-shadow: 5px 5px rgb(102 102 102 / 10%);
  padding: 0;
}

/* .popover:before {
  box-shadow: 5px 5px rgb(102 102 102 / 10%);
  padding: 0;
} */
