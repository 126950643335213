.ConsultWarranty .card-analise{
    background-color: #35aa47;
    border-radius: 0px;
}

.ConsultWarranty .card-peça {
    background-color:  #4b77be;
    border-radius: 0px;
}

.ConsultWarranty .card-peça-disponivel {
    background-color: #8e44ad;
    border-radius: 0px;
}

.ConsultWarranty .card-aguardando {
    background-color: #e08283;
    border-radius: 0px;
}

.ConsultWarranty .card-total {
    background-color: #2c3e50;
    border-radius: 0px;
}

.ConsultWarranty .tamanho-card {
    padding: 10px
}

.ConsultWarranty .tamanho-card-peça {
    padding: 9px;
}

.ConsultWarranty .botao-cards {
    color: #fff;
    font-size: 15px;
    font-weight: normal;
    margin-top: 9px;
} 

.ConsultWarranty .botao-filtrar {
    color: #fff;
    background-color: #2D567B;
    padding: 7px 14px;
    font-size: 14px;
    font-weight: normal;
    border: none;
}

.ConsultWarranty .botao-filtrar:hover,
.ConsultWarranty .botao-filtrar:focus,
.ConsultWarranty .botao-filtrar:active {
    background-color: #7EACC4;
}

.ConsultWarranty .linha-solicitacao {
    margin-top: -10px;
}

.ConsultWarranty .botao-excel {
    padding: 7px 14px;
    font-size: 14px;
    font-weight: normal;
    color: #fff;
    background-color: #35aa47;
    border: none;
}

.ConsultWarranty .botao-excel:hover,
.ConsultWarranty .botao-excel:focus,
.ConsultWarranty .botao-excel:active {
    background-color: #35aa47;
}

.ConsultWarranty .engloba-icones {
    display: block;
    margin-left: auto;
    font-size: 20px;
}

@media (min-width: 992px) {
    .ConsultWarranty .table-text-break {
        white-space: normal;
    }
}