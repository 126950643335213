.content-pdf-2 {
  position: relative;
  width: 100%;
  height: 1120px;
  background-color: blue;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;

  img {
    width: 100%;
    height: 100%;
    background-size: 100%;
  }
}

.content-title {
  position: absolute;
  top: 139px;
  left: 65px;
  z-index: 2;
  font-weight: normal;
  font-size: 64px;
  line-height: 79.24px;
  color: #fff;
  font-family: "WorkSans", sans-serif;
}

.image-vector-white-2 {
  position: absolute;
  bottom: 0;
}
