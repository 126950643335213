.form-module {
  .container-button {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    .button-module {
      background-color: #FF601A;
      color: #fff;
    }
  }
}
