.gcloud-print .alerta-gerar-token{
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #a94442;
    border-color: #ebccd1;
}

.gcloud-print .titulo-pagina-impressora{
    color: #666;
    font-size: 26px;
    margin-bottom: 20px;
    font-weight: 500;
}

.gcloud-print .titulo-card-config {
    display: flex;
    color: #666;
    font-size: 18px;
    margin-bottom: 10px;
}

.gcloud-print .btn-login-google{
    background-color: #dd4b39;
    padding: 9px 16px 9px 16px;
    font-size: 16px;
    color: #fff;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: fit-content;
    display: flex;
    align-items: center;
}

.gcloud-print .titulo-select-impressora {
    display: flex;
    color: #666;
    font-size: 18px;
    margin-bottom: 10px;
}

.gcloud-print .alert-conecte {
    background-color: #d9edf7;
    border-color: #bce8f1;
    color: #31708f;
}