.cartao-planilhas .nav-pills .nav-link.active,
.cartao-planilhas .nav-pills .show > .nav-link,
.cartao-planilhas .nav-pills .nav-link.active,
.cartao-planilhas .nav-pills .show > .nav-link:hover,
.cartao-planilhas .nav-pills .nav-link.active, 
.cartao-planilhas .nav-pills .show > .nav-link:focus,
.cartao-planilhas .nav-pills .nav-link.active,
.cartao-planilhas .nav-pills .show > .nav-link:active{
    color: #555 !important;
    cursor: default;
    background-color: #fff !important;
    border: 1px solid #ddd;
    border-bottom-color: transparent;

}
.cartao-planilhas .nav-item-planilhas {
    width: 32%;
}
.cartao-planilhas .nav-tabs > li > a,
.cartao-planilhas .nav-pills > li > a  {
color: #0072bc !important;
font-size: 14px;
font-weight: normal !;
 
}

.cartao-planilhas h4 {
    color: #4db3a2;
    font-size: 13px;
    margin-bottom: 20px;
}

.cartao-planilhas label {
    color: rgb(0, 0, 0);
    font-weight: normal;
    margin-left: 56px;
}

.cartao-planilhas .icone-planilhas {
    margin-right: 7px;
}

.cartao-planilhas .alert-success  {
    background-color: #d9edf7 ;
    color: #31708f;
    border-color: #bce8f1;
    font-size: 13px;
}

.cartao-planilhas .linha-planilhas {
    margin-top: 0px;
}

.cartao-planilhas .baixar-planilhas,
.cartao-planilhas .baixar-planilhas:hover,
.cartao-planilhas .baixar-planilhas:focus,
.cartao-planilhas .baixar-planilhas:active {
    color: #fff;
    background-color: #2D567B;
    padding: 7px 14px;
    font-weight: normal;
    font-size: 14px;
    border: none;
}


