.container-pdf-7-top-header {
  display: flex;
  align-items: center;
}

.container-pdf-7-top-header-img {
  margin-right: 18px;
}

.container-pdf-7-top-header-span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: 0.03em;

  color: #FF601A;
}

.installation-ower {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 35px auto;
  padding: 10px 27px;
  border: 1.5px solid #ffba00;
  border-radius: 5px;
  width: 100%;
  max-width: 309px;
}

.image-product {
  height: 300px;
  object-fit: contain;
  margin-bottom: 20px;
}

.installation-ower img {
  width: 16px;
  height: 16px;
}

.installation-ower span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */

  color: #545454;
}

.installation-ower p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  /* identical to box height */
  margin: 0;
  color: #545454;
}

.container-pdf-7 table {
  width: 100%;
  border: 1px solid #e5e5e5;
}

tr:nth-child(even) {
  background-color: #fff;
}

.container-pdf-7 table tr th {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 12px;
  /* identical to box height */

  text-align: center;
}

.container-pdf-7 table tr td {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */
  border: 1px solid #e5e5e5;
  text-align: center;
}
.container-pdf-7 .average-cost {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  border: 1px solid #FF601A;
  margin-top: 4px;
  background: #fff7f2;
}

.container-pdf-7 .average-cost span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #FF601A;
}

.container-pdf-7 .average-cost p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 15px;
  color: #FF601A;
  margin: 0;
}
