#col-crop {
  #cropSliders {
    position: absolute;
    bottom: -70px;
  }
  .hover-img-upload {
    width: 150px;
    height: 40px;
    color: #fff;
    font-weight: 700;
  }
}
