.insercao-manual .engloba-titulo-icones {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.insercao-manual .titulo-insercao {
    display: flex;
    color: #777;
    font-size: 16px;
    margin-top: 3px;
    font-weight: normal;
    }

.insercao-manual .user-plus {
    margin-right: 5px;
}