.footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer-proposta .footer-p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  padding: 0;
  /* identical to box height, or 190% */
  white-space: nowrap;
  margin: 0;

  color: #d2d2d2;
}
