.hierarquia h1 {
    font-size: 26px;
    font-weight: normal;
    margin-bottom: 20px;
}

.hierarquia .titulo-hieranquia {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: normal;
}

.hierarquia .titulos-formulario {
    font-size: 23px;
    font-weight: normal;
    margin-bottom: 5px;
    margin-top: 15px;
}

.hierarquia hr {
    margin-top: 7px;
}

.hierarquia label {
    margin-top: 10px;
    font-weight: normal;
}

.input-group-text {
    background-color: #e5e5e5 !important;
}

.hierarquia .salvar,
.hierarquia .salvar:hover,
.hierarquia .salvar:focus,
.hierarquia .salvar:active {
    color: #fff;
    background-color: #2D567B;
    display: flex;
    align-items: center;
    font-weight: normal;
    padding: 7px 14px;
    border: none;
}