.events-create h3{
    font-size: 23px;
    font-weight: normal;
    margin-bottom: 15px;
    margin-top: 10px;
}

.events-create h4{
    font-size: 18px;
    font-weight: normal;
}

.events-create .linha-incluir {
    margin-top: 0px;
}

.events-create .subtitulo-incluir{
    font-size: 13px;
    
}

.events-create .nav-pills .nav-link.active, 
.events-create .nav-pills .show > .nav-link {
    border-bottom: 4px solid #0179c4 !important;
    background-color: transparent !important;
    color: #000;
}


.events-create form {
    margin-bottom: 15px;
}

.events-create #texto{
    height: 300px;
    margin-bottom: 50px;
}

.events-create .navegacao {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.events-create #pequenoTexto {
    height: 110px;
}

.events-create .alert-success  {
    background-color: #d9edf7;
    border-color: #959899;
    color: #31708f;
    font-size: 13px;
}

.events-create ul {
    margin-bottom: 1px;
}

.events-create .criar-eventos,
.events-create .criar-eventos:active,
.events-create .criar-eventos:focus,
.events-create .criar-eventos:hover {
    padding: 7px 14px;
    font-weight: normal;
    color: #fff;
    background-color: #2D567B;
    border: none;
}

.events-create .botao-input,
.events-create .botao-input:hover,
.events-create .botao-input:focus,
.events-create .botao-input:active {
    color: #7EACC4;
    background-color: #2D567B;
    border: none;
}

.events-create .botao-turma,
.events-create .botao-turma:active,
.events-create .botao-turma:hover,
.events-create .botao-turma:focus{
    display: flex;
    align-items: center;
    color: #2D567B;
    background-color: #7EACC4;
    font-weight: normal;
    border: none;
}

.events-create .engloba-formulario{
    margin-left: 150px;
}

.events-create #tituloEvento{
    width: 30%;
}

.events-create p {
    margin-left: 10px;
}

.events-create .alert-p {
    margin-bottom: 0;
    margin-top: 0;
}

.events-create .dados-p {
    margin-top: 10px;
    margin-bottom: 15px;
}