@import "../../styles/colors/colors.scss";

.input {
  width: 100%;
  height: 30px;
  // border: none;
  border-radius: 5px;

  &.erros {
    border: 1px solid #7c0000;
  }

  &:focus {
    border: 1px solid $solar-main-color;
  }
}

.erros {
  color: #7c0000;
}

.solar-input-title {
  margin: 0;
  margin-bottom: 5px !important;
  margin-top: 1.5rem !important;
}
