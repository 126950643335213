.checkbox {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0.5em 0;
}
input {
  text-transform: uppercase;
}
.checkbox input {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 2;
  appearance: none;
  -webkit-appearance: none;
}
.box {
  padding: 5px;
  z-index: 1;
  background: #f7f7f7;
  border: 2px solid #d1d1d1;
  color: rgb(63, 63, 63);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.6s;
  min-height: 160px;
}
.box img {
  width: 50%;
  margin-bottom: 20px;
  margin-top: 20px;
}
.box p {
  margin: 0;
}
.checkbox input:checked ~ .box {
  background: #f7f7f7;
  border: 2px solid #185adb;
  color: #185adb;
}
.checkbox input:checked ~ .box p {
  color: #185adb !important;
}
.ver-todos {
  display: block;
  color: #58b7ff;
  margin-left: auto;
  margin-top: 20px;
  padding: 5px 20px;
  border-bottom: 1px solid #e7e7e7;
  width: fit-content;
  font-weight: bold;
}
.ver-todos i {
  margin-left: 10px;
}
.buscar-produto {
  color: #ffffff !important;
  background-color: #13b013 !important;
  margin-left: auto !important;
  display: block !important;
  width: fit-content !important;
  font-size: 14px !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
}

.gerar-pdf {
  color: #ffffff !important;
  background-color: #13b013 !important;
  display: block !important;
  font-size: 14px !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
}

.ocultar {
  width: fit-content !important;
  margin-left: auto !important
}

.subtitulo {
  font-size: 20px;
  font-weight: bold;
}
.parceiro-input {
  width: 100%;
}
.align-center {
  text-align: center;
}
.label-titulo {
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 17px;
}
.linha {
  margin: 30px 0;
}
.search-advanced {
  display: flex;
  align-items: center;
}