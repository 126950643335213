.status_container {
  width: 100%;
  height: 100%;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  overflow: hidden;
}

.status_container .title {
  font-size: 23;
  font-weight: bold;
  color: #202833;
  margin-bottom: 35;
}

.status_container .container {
  display: flex;
  flex-direction: column;
  height: fit-content;
  padding: 10px 20px;
  border-radius: 8px;
  margin: 10px 0;
}

.status_container div::-webkit-scrollbar {
  width: 12px;
}

.status_container div::-webkit-scrollbar-track {
  border-bottom-right-radius: 6px;
  border-top-right-radius: 6px;
  background: #f0f0f0; /* color of the tracking area */
}

.status_container div::-webkit-scrollbar-thumb {
  background-color: #cecece; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
  border: 3px solid #f0f0f0; /* creates padding around scroll thumb */
}

.status_container .container hr {
  margin-top: 0.3rem !important;
  margin-bottom: 0.5rem !important;
}

.status_container .container p {
  display: flex;
  align-items: center;
  margin: 5px 0 !important;
}

.status_container .container .top .name {
  font-weight: bold;
  text-transform: uppercase;
}

.status_container .container .top .date {
  font-size: 11px;
  font-weight: normal;
  margin-left: 8px;
}

.status_container .remove {
  background-color: #f8d7da;
  color: #721c24;
}

.status_container .add {
  background-color: #d4edda;
  color: #155724;
}

.status_container .edit {
  background-color: #cce5ff;
  color: #004085;
}

.status_container .message {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
