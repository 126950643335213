.button-choose-company-solar {
  background-color: #fff;
  border: 1px solid #FF601A;
  color: #FF601A;
  font-weight: 600;
  padding: 9px 28px;
  border-radius: 19px;
  font-size: 12px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.09);
}

.notFoundAr {
  background-color: #f7f9fd !important;
}
.notFoundSolar {
  background-color: #fff !important;
}

.button-choose-company-adias {
  background-color: #fff;
  border: 1px solid #2D567B;
  font-weight: 600;
  color: #2D567B;
  padding: 9px 28px;
  border-radius: 19px;
  font-size: 12px;
  box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.09);
}

.container-buttons-choose-company {
  padding: 0 12px;
}

.header-button-desktop-text {
  display: inline;
}
.header-button-mobile-text {
  display: none;
}

.header-button-right-arrow {
  display: inline;
}

.header-button-icon-image {
  display: inline;
}

@media only screen and (max-width: 768px) {
  .button-choose-company-adias {
    background-color: #fff;
    border: 1px solid #005696;
    color: rgba(42, 42, 42, 1);
    padding: 5px 10px;
    border-radius: 19px;
    font-size: 12px;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.09);
    display: flex;
  }

  .button-choose-company-solar {
    background-color: #fff;
    border: 1px solid #FF601A;
    color: #FF601A;
    padding: 20px 15px;
    border-radius: 19px;
    font-size: 12px;
    box-shadow: 0px 5px 7px rgba(0, 0, 0, 0.09);
    display: flex;
  }

  .header-button-desktop-text {
    display: none;
  }

  .header-button-mobile-text {
    display: inline;
  }

  .header-button-right-arrow {
    display: none;
  }

  .header-button-icon-image {
    display: none;
  }

  .cep-div {
    border-radius: 19px;
    font-size: 10px;
    width: 140px;
    height: 65px;
    display:flex;
  }
  .cep-div-isSolar {
    border-radius: 19px;
    font-size: 10px;
    width: 150px;
    height: 65px;
    display:flex;
  }

  .cep-icon {
    display: none;
  }
}

.cep-div {
  display: flex;
  flexdirection: row;
  border: 2px solid #2D567B;
  color: #2D567B;
  font-weight: 800;
  padding: 5px 30px 5px 15px;
  border-radius: 19px;
  marginleft: 10;
  font-size: 12px;
  cursor: pointer;
}

.cep-div-isSolar {
  display: flex;
  flexdirection: row;
  border: 1px solid #FF601A;
  color: #FF601A;
  padding: 5px 30px 5px 15px;
  border-radius: 19px;
  marginleft: 10;
  font-size: 12px;
  cursor: pointer;
}
