.order-details .numero-pedido {
  font-size: 27px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.order-details .texto-pedido {
  margin-bottom: 50px;
}

.order-details {
  .ver-mais {
    margin-right: 30px;
    margin-top: -16px;
    color: #2D567B !important;
    background-color: #BBCAD2 !important;
    border: none !important;
  }
  .ver-mais-solar {
    margin-right: 30px;
    margin-top: -16px;
    color: #FF601A !important;
    background-color: #FFE0D3 !important;
    border: none !important;
  }
}

.order-details .await-order {
  animation: animate 1.5s linear infinite;
}

@keyframes animate {
  0% {
    opacity: 0.2;
  }

  50% {
    opacity: 0.7;
  }

  100% {
    opacity: 0.2;
  }
}

.order-details .titulo-items {
  font-size: 16px;
  color: #269fec;
}

.order-details .button-items {
  display: flex;
  margin-bottom: 4px !important;
}

.order-details {
  .botoes-itens {
    color: #2D567B !important;
    display: flex !important;
    align-items: center !important;
  }
  .botoes-itens-solar {
    color: #FF601A !important;
    display: flex !important;
    align-items: center !important;
  }
}

.order-details .icones-status {
  margin-right: 5px;
}

.order-details .titulo-dados {
  font-size: 20px;
  margin-top: 40px;
  margin-bottom: 20px;
}
.order-details .img-dados {
  color: #269fec !important;
  font-size: 22px;
  display: block;
}
.order-details .img-pedidos-data {
  margin-right: 150px;
}
.order-details .text-order {
  color: #939393;
  margin-bottom: 0;
}
.order-details .cardbody-dados {
  padding: 30px !important;
}
.order-details .texto-cliente {
  margin-bottom: 0px !important;
}
.order-details .icones-dados-cliente {
  font-size: 19px !important;
  color: #269fec !important;
  margin-left: 10px;
}

.order-details .texto-fantasia {
  color: #dbdbdb;
  margin-bottom: 0 !important;
  margin-bottom: 10px !important;
}
.order-details .sku {
  margin-right: 30px !important;
}
.order-details .texto-data {
  margin-bottom: 0px !important;
}
.order-details .dados-cliente {
  font-size: 16px;
  color: #269fec !important;
}
.order-details .texto-dados {
  display: flex;
}

.order-details .texto-cliente {
  display: flex;
  font-size: 16px;
}
.order-details .botao-orders {
  color: #2e83c3 !important;
  background-color: #e5f4ff !important;
  border: none !important;
  padding: 11px 16px;
  margin-top: 20px;
  box-shadow: 0px 4px 7px 0px #e1e1e1;
}

.order-details .botao-orders-solar {
  color: #FF601A !important;
  background-color: #FFE0D3 !important;
  border: none !important;
  padding: 11px 16px;
  /* margin-top: 20px; */
  box-shadow: 0px 4px 7px 0px #e1e1e1;
}

.order-details .order-data-title h5 {
  margin-top: 20px !important;
  font-size: 20px !important;
  font-weight: 500;
}

.order-details .icone-nf {
  color: #269fec !important;
  font-size: 19px;
  margin-left: 10px;
}

.order-details .tr-table {
  border-bottom: 1px solid #aebbcd;
}

.order-details .tr-table td {
  padding: 8px !important;
}
.order-details .tr-table:last-child {
  border-bottom: none;
}

.order-details .img-pedidos {
  width: 22px;
  margin-left: 5px;
}

.order-details .texto-order-data {
  display: flex;
}

.order-details .cardbody-data {
  padding: 30px !important;
}
.order-details .divisao {
  margin-top: 20px;
  border-top: 1px solid #f5f5f5;
}
.order-details .titulo-itens {
  font-size: 20px;
  font-weight: 500;
  margin-top: 15px;
  margin-bottom: 30px;
}
.order-details .texto-pedidos-dados {
  margin-bottom: 15px;
}

.order-details .bairro {
  margin-top: 15px;
}

.order-details .texto-td-itens {
  font-weight: normal;
  text-align: center;
}

.order-details .texto-itens-percent {
  color: #2e83c3 !important;
  text-align: center;
  font-weight: normal;
}
.order-details .texto-itens-azul {
  color: #2e83c3 !important;
}
.order-details .texto-itens-verde {
  color: #28b300;
}
.order-details .texto-td-bold {
  font-weight: bold;
}

.order-details .box-envio-docs {
  box-shadow: none !important;
  margin-top: 30px;
}

.order-details .card-pagamentos-detalhes {
  box-shadow: none !important;
  border: none !important;
}

.order-details .card-gfe {
  box-shadow: none !important;
}

.order-details .card-boletos {
  box-shadow: none !important;
}

.order-details .card-produtos {
  box-shadow: none !important;
}

.order-details .doc-analise {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}
.order-details .doc-analise h6 {
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0;
}

.order-details .doc-analise p {
  font-size: 12px;
  font-weight: 500;
  color: #939393;
  margin-bottom: 0;
}

.order-details .card-pagamentos {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 20px;
}
.order-details .card-pagamentos h6 {
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0;
}
.order-details .card-pagamentos p {
  font-size: 12px;
  font-weight: 500;
  color: #939393;
  margin-bottom: 0;
}

.order-details .table > :not(caption) > * > * {
  border-top-width: 0px !important;
}

.order-details .titulo-pagamentos {
  color: #005696;
}

.order-details .gfe {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 20px;
}

.order-details .gfe h6 {
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0;
}

.order-details .gfe p {
  font-size: 12px;
  font-weight: 500;
  color: #939393;
  margin-bottom: 0;
}

.order-details .slips {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 20px;
}
.order-details .slips h6 {
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0;
}

.order-details .slips p {
  font-size: 12px;
  font-weight: 500;
  color: #939393;
  margin-bottom: 0;
}

.order-details .produtos-comprados {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  margin-bottom: 20px;
}

.order-details .produtos-comprados h6 {
  font-size: 20px;
  font-weight: 500;
  margin-right: 10px;
  margin-bottom: 0;
}

.order-details .produtos-comprados p {
  font-size: 12px;
  font-weight: 500;
  color: #939393;
  margin-bottom: 0;
}

.order-details .border-data {
  border-top: 1px solid #f5f5f5;
}

.order-details .texto-cliente-data {
  display: flex;
  font-size: 16px !important;
}

.order-details .botao-cancelado {
  font-weight: 500;
  background-color: #ffe8e8 !important;
  color: #b80000 !important;
  width: fit-content;
}

.order-details .menu-link {
  font-weight: 500;
}

.link-bloq {
  margin-left: 10px;
  color: #b80000;
  text-decoration: underline !important;
}

.btn-rounded {
  border: 1px solid transparent !important;
}

.dropdown-menu {
  width: 100%;
}

.button-items .btn {
  margin-right: 1rem !important;
}
.order-details .menu-link-icone {
  margin: 0 5px;
}

.modal-atividade .titulo-modal {
  display: flex;
  align-items: center;
}

.modal-atividade .icone {
  margin: 5px;
}

.modal-atividade .historico-pedido {
  margin-left: 1.5rem;
  border-left: 3px solid rgb(13, 109, 235);
  border-right: 1px solid #e7e7e7;
}
.modal-atividade .nodeHistorico {
  padding-left: 1rem;
  padding-bottom: 1.5rem;
  position: relative;
}
.modal-atividade .nodeHistorico .text-info,
.node p {
  margin: 0;
}
.modal-atividade .nodeHistorico::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 2px solid #fff;
  background-color: rgb(13, 109, 235);
  border-radius: 50%;
  position: absolute;
  top: 0.3rem;
  left: -0.57rem;
}

.modal-atividade .historico-transporte {
  margin-left: 1.5rem;
  border-left: 2px solid #ddd;
}

.modal-atividade .nodeTransporte {
  padding-left: 1rem;
  padding-bottom: 1.5rem;
  position: relative;
}
.modal-atividade .nodeTransporte .text-info,
.node p {
  margin: 0;
}
.modal-atividade .nodeTransporte::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 2px solid #000;
  border-radius: 50%;
  position: absolute;
  top: 0.3rem;
  left: -0.57rem;
}
