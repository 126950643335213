.neworder .checkbox {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0.5em 0;
}

.neworder input {
  text-transform: uppercase;
}

.neworder .checkbox input {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 2;
  appearance: none;
  -webkit-appearance: none;
}

.neworder .rbt-aux .close {
  top: 7px !important;
  left: -17px !important;
  right: auto !important;
}
.neworder .box {
  padding: 5px;
  z-index: 1;
  background: #f7f7f7;
  border: 2px solid #d1d1d1;
  color: rgb(63, 63, 63);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.6s;
  min-height: 160px;
}

.neworder .box img {
  width: 50%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.neworder .box p {
  margin: 0;
}

.neworder .checkbox input:checked ~ .box {
  background: #f7f7f7;
  border: 2px solid #185adb;
  color: #185adb;
}

.neworder .checkbox input:checked ~ .box p {
  color: #185adb !important;
}

.neworder .product-infos {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.neworder .product-infos .product-info {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.neworder .product-infos .product-info .info-title {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.neworder .product-infos .product-info .info-result {
  padding: 5px 20px;
}

.neworder .product-infos .product-info .borded {
  border: 2px solid;
  border-radius: 5px;
}

.neworder .carousel-control-prev-icon,
.neworder .carousel-control-next-icon {
  width: 25px !important;
  background-color: transparent !important;
}

.neworder .carousel-control-prev,
.neworder .carousel-control-next {
  opacity: 1 !important;
  width: 5% !important;
}

.neworder .filter-order {
  padding: 35px !important;
}

.neworder .form-control {
  border-radius: 8px !important;
}

.neworder .ver-todos {
  display: block;
  color: #58b7ff;
  margin-left: auto;
  margin-top: 20px;
  padding: 5px 20px;
  border-bottom: 1px solid #e7e7e7;
  width: fit-content;
  font-weight: bold;
}

.neworder .ver-todos i {
  margin-left: 10px;
}

.neworder .form-check-input[type="radio"] {
  border-radius: 20% !important;
}

.neworder .buscar-produto {
  color: #ffffff !important;
  background-color: #2D567B !important;
  margin-left: auto !important;
  display: block !important;
  width: fit-content !important;
  font-size: 14px !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
}

.neworder .buscar-produto:hover {
  background-color: #7EACC4 !important;
  border-color: #7EACC4;
}

.neworder .campo-buscar {
  position: absolute !important;
  color: #767676 !important;
  margin-right: 20px !important;
}

.neworder .ml-auto {
  margin-left: auto;
}

.neworder .ocultar {
  margin-left: 10px;
}

.neworder .subtitulo {
  font-size: 20px;
  font-weight: bold;
}

.neworder .cap-selected {
  color: #5c9fd2;
  background-color: #f0f9ff;
  width: fit-content;
}

.neworder .selo-a {
  width: 50px;
  margin-left: 10px;
}

.neworder .parceiro-input {
  width: 100%;
}

.neworder .align-center {
  text-align: center;
}

.neworder .pre-venda {
  margin: 0 auto !important;
  border: 2px solid #929fb1 !important;
  background-color: #f8f9fa !important;
  color: #929fb1 !important;
  width: 90% !important;
  text-align: center;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  font-weight: bold !important;
}

.neworder .val-total {
  font-weight: bold;
  text-align: center;
  border: 2px solid transparent !important;
  padding: 5px 10px;
}

.neworder .disp-text {
  text-align: center;
  font-size: 12;
  color: #13b013;
  margin-bottom: 0;
}

.neworder .disp-text,
.neworder .campo-valores {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.neworder .but-add {
  background-color: #13b013 !important;
  color: #ffffff !important;
  width: 100% !important;
  padding: 10px 20px !important;
}

.neworder .but-modal {
  background-color: #ebffeb !important;
  border: 1px solid #ebffeb;
  color: #28b300 !important;
  width: 100% !important;
  padding: 10px 20px !important;
}

.neworder .form-check-input:checked {
  background-color: #28b300;
  border-color: #28b300 !important;
}

.neworder .modalidade:checked {
  border-color: #269fec !important;
  background-color: #269fec;
}

.neworder .track-order-list li.completed .progress-line::before {
  top: 12px !important;
}

.neworder .label-titulo {
  font-weight: bold;
  font-size: 17px;
}

.neworder .linha {
  margin: 30px 0;
}

.neworder .prazo-frete:focus,
.neworder .prazo-frete:active {
  border: 2px solid #269fec;
}

.neworder .prazo-frete-busca {
  border-radius: 8px 0 0 8px !important;
}

.neworder .btn-prazo-frete-busca {
  color: #fff;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.neworder .btn-prazo-active {
  background-color: #269fec !important;
  border: 2px solid #269fec;
}

.neworder .label-prazo-active,
.neworder .label-prazo-active a {
  color: #269fec !important;
  font-weight: bold;
}

.neworder .my-rbt-aux,
.neworder .rbt-aux {
  width: auto;
}
.neworder .rbt-loader {
  display: none;
}

.neworder .erro-field {
  border: 1px solid #ff0000 !important;
}

.neworder .carregando-emissao {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.438);
  position: fixed;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}

.neworder .controle-remoto {
  position: fixed;
  z-index: 10000;
  top: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #d4d4d4;
}

.neworder .controle-remoto a {
  display: block;
  font-size: 25px;
  cursor: pointer;
}

.neworder .controle-remoto a:hover {
  color: #28b300 !important;
  border-bottom: 2px solid #28b300;
}

.text-indigo {
  color: #404b5a !important;
}

.bg-light-gray {
  background-color: rgba(240, 249, 255, 1) !important;
}

.relative {
  position: relative;
}

.spinner-absolute {
  top: 7px;
  right: 10px;
  position: absolute;
}

.rounded-bg {
  border-radius: 50px;
  background-color: rgba(240, 249, 255, 1) !important;
}

.list-type-none {
  list-style-type: none;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.position-ambient {
  right: 5px;
  color: #269fec;
}
