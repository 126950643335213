.order-details .numero-pedido {
  font-size: 27px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.order-details .texto-pedido {
  margin-bottom: 50px;
}

.order-details .button-voltar {
  color: #ffffff !important;
  background-color: #2D567B !important;
  display: block !important;
  width: fit-content !important;
  font-size: 14px !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
}

.order-details .texto-dados {
  display: flex;
}

.order-details .texto-cliente {
  display: flex;
  font-size: 16px;
}

.nodeTransporte {
  padding-left: 1rem;
  padding-bottom: 1.5rem;
  position: relative;
}
.nodeTransporte .text-info,

.node p {
  margin: 0;
}

.nodeTransporte::before {
  content: "";
  width: 1rem;
  height: 1rem;
  background: #fff;
  border: 2px solid #000;
  border-radius: 50%;
  position: absolute;
  top: 0.3rem;
  left: -0.57rem;
}

.historico-transporte {
  margin-left: 1.5rem;
  border-left: 2px solid #ddd;
}