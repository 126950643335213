.event-Teams h3 {
font-size: 26px;
font-weight: normal;
}

.event-Teams .subtitulo-incluir-turma {
    font-size: 13px;
}

.event-Teams h4 {
   font-size: 18px;
   font-weight: normal;
}

.event-Teams form {
    margin-bottom: 15px;
}

.event-Teams .engloba-form-turma {
    margin-left: 150px;
}

.event-Teams #vagasGeral {
    width: 30%;
}

.event-Teams #vagasEmpresa {
    width: 30%;
}

.event-Teams p {
    margin-left: 10px;
}

.event-Teams .criar-turma {
    display: block;
    margin-left: auto;
    margin-right: 40%;
    color: #fff;
    background-color: #2D567B;
    border: none;
    padding: 7px 14px;
}