.orders .selectData::placeholder {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  text-align: center;
}

.orders .card-list p {
  margin-bottom: 0;
}

.rbt-aux {
  position: absolute;
  top: 7px;
  right: 5px;
}

.cursor-pointer {
  cursor: pointer !important;
}

.img-pedidos {
  width: 22px;
  margin-left: 5px;
}

.btn-outline-info:hover {
  background-color: transparent !important;
}

@media (max-width: 756px) {
  .orders .card-list p {
    width: 100% !important;
    text-align: left !important;
    /* margin-bottom: 10px; */
  }

  .orders #dataCad::before {
    content: "Cadastro: ";
  }

  .orders #numPedido::before {
    content: "Pedido: ";
  }

  .orders #nomCli::before {
    content: "Cliente: ";
  }

  .orders #valTot::before {
    content: "Valor: ";
  }

  .orders #filaSit::before {
    content: "Situação: ";
  }

  .orders #vend1::before {
    content: "Vendedor: ";
  }

  .orders #vend2::before {
    content: "Parceiro: ";
  }

  .orders #notf::before {
    content: "Nota Fiscal: ";
  }
}
