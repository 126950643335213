.container-pdf-6-bottom {
  display: flex;
  flex-direction: column;
}

.container-pdf-6-bottom-header {
  display: flex;
  align-items: center;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
}

.container-pdf-6-bottom-header img {
  margin-right: 18px;
}

.container-pdf-6-bottom-header span {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  margin-left: 10px;
  letter-spacing: 0.03em;

  color: #FF601A;
}

.container-pdf-6-bottom-table-graphics {
  display: grid;
  grid-template-columns: 35% 64%;
  gap: 5px;
  padding-top: 30px;
  padding-left: 20px;
  padding-right: 20px;
}

.container-pdf-6-bottom-table-graphics table {
  width: 100%;
  height: 100%;
  border: 1px solid #e5e5e5;
}

tr:nth-child(even) {
  background-color: #fff;
}

.container-pdf-6-bottom-table-graphics table tr th {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 12px;
  /* identical to box height */

  text-align: center;
}

.container-pdf-6-bottom-table-graphics table tr td {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: bold;
  font-size: 9px;
  line-height: 12px;
  /* identical to box height */
  border: 1px solid #e5e5e5;
  text-align: center;
}

.container-pdf-6-bottom-table-graphics table tr .orange {
  color: #FF601A;
}

.container-pdf-6-bottom-table-graphics table tr .blue {
  color: #2D567B;
}

.container-pdf-6-bottom-graphic {
  width: 100%;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
}

.container-pdf-6-bottom p {
  font-family: "WorkSans";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 19px;
  /* or 158% */

  color: #2a2a2a;
  margin-top: 20px;
  padding-left: 40px;
  padding-right: 40px;
}

.container-pdf-6-bottom .footer-proposta {
  position: absolute;
  bottom: 5px;
  left: 22px;
  right: 22px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container-pdf-6-bottom .footer-proposta .footer-p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 19px;
  padding: 0;
  /* identical to box height, or 190% */
  white-space: nowrap;
  margin: 0;

  color: #d2d2d2;
}
