.erros {
  color: #ff4b4b;
}

.solar-styles .icon-absolute-left {
  top: 78px !important;
  @media (max-width: 769px) {
    top: 78px !important;
  }
}
