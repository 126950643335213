.content-pdf-3 {
  position: relative;
  width: 100%;
  height: 1120px;
  min-width: 791px;
  max-width: 791px;
  margin: 0px auto;
}

.container-text {
  margin-left: 124px;
  margin-right: 25px;
  z-index: 6;
}

.content-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 128px;
}

.contact {
  color: #0090a3;
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  z-index: 1;
}

.contact span {
  font-family: "WorkSans", sans-serif;
}

.image-logo-3 {
  width: 159px;
  height: 98px;
}

.container-text p {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  text-align: right;

  color: #3f3f3f;
}

.container-text .strong {
  font-family: "WorkSans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  z-index: 5;
  color: #000000;
}

.background-image {
  position: absolute;
  bottom: 0;
  z-index: 4;
  left: 0;
  right: 0;
  width: 100%;
}

.middle {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
}

.middle .retangolo {
  position: absolute;
  width: 53%;
  left: 0;
  top: 0px;
}

.middle .retangolo-claro {
  position: absolute;
  width: 60%;
  right: 0;
  bottom: 151px;
}

.middle .retangolo-black {
  position: absolute;
  bottom: 57%;
  right: 0;
  width: 589px;
  height: 110px;
  z-index: 3;
}

.middle .retangolo-black img {
  width: 100%;
}

.middle .retangolo-black span {
  position: absolute;
  right: 0;
  top: 7px;
  bottom: 0;
  z-index: 5;
}

.middle .retangolo-black span .title {
  margin: 0;
  margin-right: 145px;

  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 53px;
  color: #fff;
}

.middle .retangolo-black span .subscription {
  margin: 0;
  position: absolute;
  right: 16px;

  font-family: "WorkSans";
  font-style: normal;
  font-weight: 600;
  font-size: 35px;
  line-height: 2px;
  color: #fff;
}
