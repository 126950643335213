.solar .checkbox {
  width: 100%;
  height: auto;
  position: relative;
  margin: 0.5em 0;
}

.solar input {
  text-transform: uppercase;
}

.solar .checkbox input {
  position: absolute;
  cursor: pointer;
  width: 100%;
  height: 100%;
  z-index: 2;
  appearance: none;
  -webkit-appearance: none;
}

.input-icon {
  width: 84%;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: 0.47rem 1.75rem 0.47rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
  appearance: none;
}
.solar .box {
  padding: 5px;
  z-index: 1;
  background: #f7f7f7;
  border: 2px solid #d1d1d1;
  color: rgb(63, 63, 63);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  transition: all 0.6s;
  min-height: 160px;
}

.solar .box img {
  width: 50%;
  margin-bottom: 20px;
  margin-top: 20px;
}

.solar .box p {
  margin: 0;
}

.solar .checkbox input:checked ~ .box {
  background: #f7f7f7;
  border: 2px solid #185adb;
  color: #185adb;
}

.solar .checkbox input:checked ~ .box p {
  color: #185adb !important;
}

.solar .product-infos {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.solar .product-infos .product-info {
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.solar .product-infos .product-info .info-title {
  height: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solar .product-infos .product-info .info-result {
  padding: 5px 20px;
}

.solar .product-infos .product-info .borded {
  border: 2px solid;
  border-radius: 5px;
}

.solar .carousel-control-prev-icon,
.solar .carousel-control-next-icon {
  width: 25px !important;
  background-color: transparent !important;
}

.solar .carousel-control-prev,
.solar .carousel-control-next {
  opacity: 1 !important;
  width: 5% !important;
}

.solar .filter-order {
  padding: 35px !important;
}

.solar .form-control {
  border-radius: 8px !important;
}

.solar .ver-todos {
  display: block;
  color: #58b7ff;
  margin-left: auto;
  margin-top: 20px;
  padding: 5px 20px;
  border-bottom: 1px solid #e7e7e7;
  width: fit-content;
  font-weight: bold;
}

.solar .ver-todos i {
  margin-left: 10px;
}

.solar .form-check-input[type="radio"] {
  border-radius: 20% !important;
}

.solar .buscar-produto {
  color: #ffffff !important;
  background-color: #13b013 !important;
  margin-left: auto !important;
  display: block !important;
  width: fit-content !important;
  font-size: 14px !important;
  padding: 20px 40px !important;
  border-radius: 8px !important;
}

.solar .campo-buscar {
  position: absolute !important;
  color: #767676 !important;
  margin-right: 20px !important;
}

.solar .loading-parceiro {
  position: absolute !important;
  font-size: 14px;
  margin-right: 20px !important;
}

.solar .ml-auto {
  margin-left: auto;
}

.solar .ocultar {
  margin-left: 10px;
}

.solar .subtitulo {
  font-size: 20px;
  font-weight: bold;
}

.solar .cap-selected {
  color: #5c9fd2;
  background-color: #f0f9ff;
  width: fit-content;
}

.solar .selo-a {
  width: 50px;
  margin-left: 10px;
}

.solar .parceiro-input {
  width: 100%;
}

.solar .align-center {
  text-align: center;
}

.solar .pre-venda {
  margin: 0 auto !important;
  border: 2px solid #929fb1 !important;
  background-color: #f8f9fa !important;
  color: #929fb1 !important;
  width: 90% !important;
  text-align: center;
  padding: 5px 10px !important;
  border-radius: 5px !important;
  font-weight: bold !important;
}

.solar .val-total {
  font-weight: bold;
  text-align: center;
  border: 2px solid transparent !important;
  padding: 5px 10px;
}

.solar .disp-text {
  text-align: center;
  font-size: 12;
  color: #13b013;
  margin-bottom: 0;
}

.solar .disp-text,
.solar .campo-valores {
  min-height: 70px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.solar .but-add {
  background-color: #FF601A !important;
  color: #ffffff !important;
  width: 100% !important;
  padding: 10px 20px !important;
  border: none;
}

.solar .but-modal {
  background-color: #FFE0D3 !important;
  color: #FF601A !important;
  width: 100% !important;
  padding: 10px 20px !important;
  border: none;
}

.solar .form-check-input:checked {
  background-color: #28b300;
  border-color: #28b300 !important;
}

.solar .form-check-input-solar:checked {
  background-color: #FF601A !important;
  border-color: #FF601A !important;
}

.solar .modalidade:checked {
  border-color: #269fec !important;
  background-color: #269fec;
}

.solar .track-order-list li.completed .progress-line::before {
  top: 12px !important;
}

.solar .label-titulo {
  font-weight: bold;
  font-size: 17px;
}

.solar .linha {
  margin-top: 0px;
  margin-bottom: 30px;
}

.solar .prazo-frete:focus,
.solar .prazo-frete:active {
  border: 2px solid #269fec;
}

.solar .prazo-frete-busca {
  border-radius: 8px 0 0 8px !important;
}

.solar .btn-prazo-frete-busca {
  color: #fff;
  border-radius: 0 8px 8px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.solar .btn-prazo-active {
  background-color: #269fec !important;
  border: 2px solid #269fec;
}

.solar .label-prazo-active,
.solar .label-prazo-active a {
  color: #269fec !important;
  font-weight: bold;
}

.solar .my-rbt-aux,
.solar .rbt-aux {
  width: auto;
}
.solar .rbt-loader {
  display: none;
}

.solar .erro-field {
  border: 1px solid #ff0000 !important;
}

.solar .carregando-emissao {
  width: 100%;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.438);
  position: fixed;
  z-index: 10000;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;
}

.solar .controle-remoto-solar {
  position: fixed;
  z-index: 10000;
  top: 35vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  right: 0;
  background-color: #fff;
  padding: 15px;
  border-radius: 5px;
  box-shadow: 5px 5px 5px #d4d4d4;
}

.solar .controle-remoto-solar a {
  display: block;
  font-size: 25px;
  cursor: pointer;
}

.solar .controle-remoto-solar a:hover {
  color: #FF601A !important;
  border-bottom: 2px solid #FF601A;
}

.solar .button-search {
  background-color: #FF601A;
  color: #fff;
  min-width: 153px;
}

.solar .button-search:hover {
  background-color: #eb681c;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .kits-neworder-filter-busca-kits-row {
    margin-block: 20px;
  }

  .kits-neworder-advanced-row {
    gap: 10px;
  }
}
